import {
  Add,
  AddCircleTwoTone,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { ResourceOrder } from "../Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import ApiService from "@/api/ApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import MultiSelector from "../elements/MultiSelector";
import { usePersistantStore } from "@/stores/global";

const fixSizes = (refs: ResourceOrder["references"], amount: number) =>
  refs.length === amount
    ? refs
    : refs.length > amount
    ? refs.slice(0, refs.length)
    : refs.concat(new Array(amount - refs.length).fill(null));

export default function AddResourceDialog<T extends ResourceOrder>({
  save,
  OpenButton,
  initialState,
  region,
}: {
  save: (order: T) => void;
  OpenButton: any;
  initialState: T;
  region: number;
}) {
  const [open, setOpen] = useState(false);
  const [showWishes, setShowWishes] = useState(false);
  const [state, setState] = useState<T>(initialState);

  const crewQuery = useQuery<Crew[], AxiosResponse>({
    queryKey: ["getCrews", state.tag, region],
    enabled: state.type === "CREW" && !!state.tag,
    queryFn: ApiService.getEntitiesByTag("crew", state.tag ?? 0, region),
  });

  const resourceQuery = useQuery<Resource[], AxiosResponse>({
    queryKey: ["getResourcesByTag", state.tag, region],
    enabled: state.type === "RESOURCE" && !!state.tag,
    queryFn: ApiService.getEntitiesByTag("resource", state.tag ?? 0, region),
  });

  const staffQuery = useQuery<StaffMember[], AxiosResponse>({
    queryKey: ["getStaffMembersByTag", state.tag, region],
    enabled: state.type === "HUMAN" && !!state.tag,
    queryFn: ApiService.getEntitiesByTag("staffMember", state.tag ?? 0, region),
  });

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setOpen(true);
  }, [initialState]);

  const valid = true;

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Ressource bestellen
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Typ">
              <ToggleButtonGroup
                orientation="horizontal"
                exclusive
                value={state.type}
                onChange={(_, type) =>
                  type &&
                  type !== state.type &&
                  (() => {
                    setState((s) => ({
                      ...s,
                      type,
                      tag: null,
                      references: [null],
                      amount: 1,
                    }));
                    setShowWishes(false);
                  })()
                }
              >
                <ToggleButton value="CREW">Kolonne</ToggleButton>
                <ToggleButton value="RESOURCE">Maschine/Fahrzeug</ToggleButton>
                <ToggleButton value="HUMAN">Mitarbeiter</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Anzahl">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  disabled={state.amount <= 1}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      amount: s.amount - 1,
                      references: fixSizes(s.references, s.amount - 1),
                    }))
                  }
                >
                  <RemoveCircleTwoTone />
                </IconButton>
                <Box sx={{ fontSize: "20px", mx: "10px" }}>{state.amount}</Box>
                <IconButton
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      amount: s.amount + 1,
                      references: fixSizes(s.references, s.amount + 1),
                    }))
                  }
                >
                  <AddCircleTwoTone />
                </IconButton>
              </Box>
            </LabelWrapper>
            <LabelWrapper label="Kategorie">
              <ManyToOneSelect
                showWithoutChoice
                remoteFilter={{
                  type: state.type,
                  region: ["region", region],
                  calendarType: ["%IN", ["WISHABLE", "REQUESTABLE"]],
                }}
                remoteFilterReady={true}
                placeholder="Bitte wählen..."
                entity={"resourceTag"}
                currentId={state.tag ?? 0}
                sideEffect={(tag) =>
                  setShowWishes(tag?.calendarType === "WISHABLE")
                }
                setId={(nid) => setState((s) => ({ ...s, tag: nid || null }))}
              />
            </LabelWrapper>

            {showWishes ? (
              <LabelWrapper label="Wunschauswahl">
                <MultiSelector
                  onChange={(references) =>
                    setState((s) => ({ ...s, references }))
                  }
                  value={state.references}
                  query={
                    state.type === "CREW"
                      ? crewQuery
                      : state.type === "HUMAN"
                      ? staffQuery
                      : resourceQuery
                  }
                />
              </LabelWrapper>
            ) : null}
            <LabelWrapper label="Automatische Buchung">
              <ToggleButtonGroup
                exclusive
                value={state.autoBooking}
                onChange={(_, autoBooking) =>
                  autoBooking && setState((s) => ({ ...s, autoBooking }))
                }
              >
                <ToggleButton color="success" value={true}>
                  Ja
                </ToggleButton>
                <ToggleButton color="error" value={false}>
                  Nein
                </ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Weitere Informationen">
              <TextField
                size="small"
                fullWidth
                value={state.comment}
                placeholder="Weitere Informationen"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    comment: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
