export function validate<Type>(
  params: Type,
  success: (data: Type) => any,
  setErrors: (message: string[]) => any,
  ignore: String[] = []
) {
  const errors = [];
  for (let key in params) {
    if (ignore.includes(key)) continue;
    if (typeof params[key] === "string" && params[key].length < 1) {
      errors.push("Feld " + key + " darf nicht leer sein.");
    } else if (typeof params[key] === "number" && params[key] === 0) {
      errors.push("Feld " + key + " darf nicht leer sein.");
    }
  }
  if (errors.length) {
    setErrors(errors);
  } else {
    setErrors([]);
    success(params);
  }
};
