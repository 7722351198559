import React, { useMemo } from "react";
import { AxiosResponse } from "axios";
import BaseApiService from "../api/BaseApiService";
import { useQuery } from "@tanstack/react-query";

interface BasicEntity {
    id: number;
    name: string;
  }

export default function useReferenceNames<T extends BasicEntity, RetType = string>(entity: string, nameFn: (o: T) => RetType = (o: T) => o.name as RetType) {
    const query = useQuery<T[], AxiosResponse>({
        queryKey: ["get" + entity[0].toUpperCase() + entity.slice(1) + "s"],
        queryFn: BaseApiService.getEntities(entity),
      });
    
      return useMemo(() => {
        if (!query.isSuccess) return new Map<number, RetType>();
        const names = new Map<number, RetType>();
        for (let o of query.data) {
          names.set(o.id, nameFn(o));
        }
        return names;
      }, [query.data]);
};