import {
  Add,
  AddCircleTwoTone,
  ArrowForward,
  Delete,
  ExpandMore,
  RemoveCircleTwoTone,
  Search,
  StarRateRounded,
  X,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import {
  Article,
  MaterialOrder,
  OrderPosition,
  ResourceOrder,
  SupplierOrder as SO,
  TruckInfo,
} from "../Project";

import SupplierOrder from "../elements/SupplierOrder";
import TimePicker from "react-time-picker";
import ApiService from "@/api/ApiService";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { tileLayer } from "leaflet";

const headCells = [
  {
    id: "number",
    label: "Artikelnummer",
    numeric: true,
    sortable: true,
  },
  {
    id: "name",
    label: "Artikelname",
    numeric: false,
    sortable: true,
  },
  {
    id: "plantName",
    label: "Lieferwerk",
    numeric: false,
    sortable: true,
  },
  {
    id: "supplierName",
    label: "Lieferant",
    numeric: false,
    sortable: true,
  },
];

export default function AddMaterialDialog({
  save,
  OpenButton,
  initialState,
  supplierOrders,
}: {
  supplierOrders: SO[];
  save: (order: MaterialOrder) => void;
  OpenButton: any;
  initialState: MaterialOrder;
}) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("100");
  const [searchField, setSearchField] = useState("");
  const [manualSelection, setManualSelection] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);
  const [state, setState] = useState<MaterialOrder>(initialState);

  useEffect(() => {if (!updateAmount) return; setUpdateAmount(false); setAmount("" + state.amount);}, [updateAmount]);

  const search = useMutation<Article[], AxiosResponse, { search: string }>({
    mutationFn: ApiService.searchMaterials(),
    onSuccess: (answer) => {
  
    },
  });

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setAmount("" + initialState.amount);
    setOpen(true);
  }, [initialState]);

  const addPosition = () => {
    const articles = state.transportInformation ?? [];
    articles.push({
      type: "XX",
      amount: null,
      thermo: false,
      bordmatik: false,
      siteWork: state.amount === -1
    });
    setState((s) => ({
      ...s,
      transportInformation: articles,
    }));
  };

  const deletePosition = (i: number) => {
    setState((s) => {
      const transportInformation = (s.transportInformation ?? []).slice();
      transportInformation.splice(i, 1);
      return {
        ...s,
        transportInformation,
      };
    });
  };

  const setPositionField = (i: number, key: keyof TruckInfo, value: any) => {
    setState((s) => ({
      ...s,
      transportInformation: (s.transportInformation ?? []).map((t, xi) => ({
        ...t,
        [key]: xi === i ? value : t[key],
      })),
    }));
  };

  const valid =
    (state.amount > 0 && !!state.article) ||
    (!state.article && state.amount === -1 && (state.transportInformation??[]).reduce<boolean | null>((c, f) => c !== false && !!f.siteWork, null));

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002196",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Material bestellen
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper
              label={
                <Fragment>
                  <Box>Artikelauswahl</Box>
                  {!state.article && state.amount !== -1 ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={manualSelection}
                            onChange={(e) =>
                              setManualSelection(e.target.checked)
                            }
                          />
                        }
                        componentsProps={{ typography: { fontSize: "90%" } }}
                        sx={{ m: "0px" }}
                        label="Freie Auswahl"
                      />
                    </FormGroup>
                  ) : null}
                </Fragment>
              }
            >
              {!!state.article || state.amount === -1 ? (
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Box>
                    {!!state.article ? (
                      <Fragment>
                        <Box>{state.article.name}</Box>
                        <Box>{state.article.number}</Box>
                        <Box>{state.article.plantName}</Box>
                        <Box>{state.article.supplierName}</Box>
                        {!state.position ? (
                          <Box>
                            <em>Unbepreister Artikel!</em>
                          </Box>
                        ) : null}
                      </Fragment>
                    ) : (
                      <Box>Ohne Materiallieferung</Box>
                    )}
                  </Box>
                  <IconButton
                    onClick={() => {
                      setState((s) => ({
                        ...s,
                        position: null,
                        article: null,
                        amount: s.amount !== -1 ? s.amount : 100,
                      })); setUpdateAmount(true);}
                    }
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ) : manualSelection ? (
                <Fragment>
                  <OutlinedInput
                    sx={{ mb: "10px" }}
                    fullWidth
                    size="small"
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? search.mutate({
                            search: searchField,
                          })
                        : null
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={search.isPending}
                          onClick={() =>
                            search.mutate({
                              search: searchField,
                            })
                          }
                          onMouseDown={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => event.preventDefault()}
                          edge="end"
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Artikel suchen..."
                    value={searchField}
                    onChange={(e) =>
                      setSearchField((e.target as HTMLInputElement).value)
                    }
                  />
                  {search.isIdle ? null : (
                    <Paper variant="outlined">
                      {search.isError ? (
                        <ErrorIndicator />
                      ) : search.isPending ? (
                        <Box
                          sx={{
                            minHeight: "100px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : search.data.length === 0 ? (
                        <Alert severity="info">
                          <AlertTitle>Nichts gefunden.</AlertTitle>
                          Hinweis: Sie müssen mindestens drei Zeichen eingeben
                          und ein Lieferwerk auswählen.
                        </Alert>
                      ) : (
                        <EnhancedTable
                          buttons={(article: Article) => (
                            <IconButton
                              size="small"
                              aria-label="edit"
                              onClick={() => {
                                setState((s) => ({
                                  ...s,
                                  position: null,
                                  amount: s.amount !== -1 ? s.amount : 100,
                                  article,
                                })); setUpdateAmount(true);}
                              }
                            >
                              <ArrowForward />
                            </IconButton>
                          )}
                          type=""
                          initialSort="name"
                          headCells={headCells}
                          rows={search.data}
                        />
                      )}
                    </Paper>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {supplierOrders.map((so, i) =>
                    so.deleted ? null : (
                      <SupplierOrder
                        so={so}
                        deleteOrder={null}
                        onSelect={(position: OrderPosition) =>
                          {setState((s) => ({
                            ...s,
                            position,
                            amount: s.amount !== -1 ? s.amount : 100,
                            article: position.article,
                          })); setUpdateAmount(true);}
                        }
                        key={i}
                      />
                    )
                  )}
                  <div style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      sx={{ mt: "10px" }}
                      onClick={() => {
                        setState((s) => ({
                          ...s,
                          position: null,
                          article: null,
                          amount: -1,
                          transportInformation: (s.transportInformation?? []).map(x => ({...x, siteWork: true}))
                        })); 
                      }}
                    >
                      Nur Baustellendienst
                    </Button>
                  </div>
                </Fragment>
              )}
            </LabelWrapper>
            <div className="extraFlex">
              {!!state.article || state.amount === -1 ? (
                <Fragment>
                  {state.amount !== -1 ? (
                    <LabelWrapper label="Menge">
                      <TextField
                        size="small"
                        fullWidth
                        value={amount}
                        placeholder="Menge"
                        multiline
                        onChange={(e) => {
                          setAmount(amount);
                          setState((s) => ({
                            ...s,
                            amount:
                              parseInt((e.target as HTMLInputElement).value) ||
                              0,
                          }));
                        }}
                      />
                    </LabelWrapper>
                  ) : null}
                  <LabelWrapper label="Erster LKW an BS">
                    <TimePicker
                      onChange={(firstUnload) =>
                        setState((s) => ({
                          ...s,
                          firstUnload,
                        }))
                      }
                      className={!state.firstUnload ? "nostart" : ""}
                      disableClock
                      value={state.firstUnload}
                    />
                  </LabelWrapper>
                </Fragment>
              ) : null}
            </div>
            {!!state.article || state.amount === -1 ? (
              <Fragment>
                <div className="truckInfo">
                  <div className="truckInfoInner">
                    {(state.transportInformation ?? []).length > 0 ? (state.transportInformation ?? []).map((ti, i) => (
                      <div key={i} className="extraFlex specialFlex">
                        <LabelWrapper label={"LKW-Anzahl " + (i + 1)}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              disabled={!ti.amount}
                              onClick={() =>
                                setPositionField(
                                  i,
                                  "amount",
                                  !ti.amount ? null : ti.amount - 1 || null
                                )
                              }
                            >
                              <RemoveCircleTwoTone />
                            </IconButton>
                            <Box sx={{ mx: "10px" }}>
                              {!ti.amount ? "egal" : ti.amount + " LKW"}
                            </Box>
                            <IconButton
                              onClick={() =>
                                setPositionField(
                                  i,
                                  "amount",
                                  !ti.amount ? 1 : ti.amount + 1
                                )
                              }
                            >
                              <AddCircleTwoTone />
                            </IconButton>
                          </Box>
                        </LabelWrapper>
                        <LabelWrapper label={"LKW-Art " + (i + 1)}>
                          <ToggleButtonGroup
                            size="small"
                            orientation="horizontal"
                            exclusive
                            value={ti.type}
                            onChange={(_, truckType) =>
                              truckType &&
                              truckType !== ti.type &&
                              setPositionField(i, "type", truckType)
                            }
                          >
                            <ToggleButton value="XX">egal</ToggleButton>
                            <ToggleButton value="SA">Sattel</ToggleButton>
                            <ToggleButton value="3A">3-Achser</ToggleButton>
                            <ToggleButton value="4A">4-Achser</ToggleButton>
                            <ToggleButton value="3A4A">Solo</ToggleButton>
                            <ToggleButton value="HZ">Hängerzug</ToggleButton>
                          </ToggleButtonGroup>
                        </LabelWrapper>
                        <LabelWrapper label={"Thermo " + (i + 1)}>
                          <ToggleButtonGroup
                            size="small"
                            orientation="horizontal"
                            exclusive
                            value={ti.thermo}
                            onChange={(_, thermo) =>
                              setPositionField(i, "thermo", thermo)
                            }
                          >
                            <ToggleButton value={false}>Nein</ToggleButton>
                            <ToggleButton value={true}>Ja</ToggleButton>
                          </ToggleButtonGroup>
                        </LabelWrapper>
                        <LabelWrapper label={"Bordmatik " + (i + 1)}>
                          <ToggleButtonGroup
                            size="small"
                            orientation="horizontal"
                            exclusive
                            value={ti.bordmatik}
                            onChange={(_, thermo) =>
                              setPositionField(i, "bordmatik", thermo)
                            }
                          >
                            <ToggleButton value={false}>Nein</ToggleButton>
                            <ToggleButton value={true}>Ja</ToggleButton>
                          </ToggleButtonGroup>
                        </LabelWrapper>
                        <LabelWrapper label={"Baustellendienst " + (i + 1)}>
                          <ToggleButtonGroup
                            size="small"
                            orientation="horizontal"
                            exclusive
                            value={!!ti.siteWork}
                            disabled={state.amount === -1}
                            onChange={(_, thermo) =>
                              setPositionField(i, "siteWork", thermo)
                            }
                          >
                            <ToggleButton value={false}>Nein</ToggleButton>
                            <ToggleButton value={true}>Ja</ToggleButton>
                          </ToggleButtonGroup>
                        </LabelWrapper>
                          <LabelWrapper label={""}>
                            <IconButton onClick={() => deletePosition(i)}>
                              <Delete />
                            </IconButton>
                          </LabelWrapper>
                      </div>
                    )) : <div style={{textAlign: "center", padding: "10px"}}>Kein LKW benötigt (z.B. eigenständige Abholung)</div>}
                  </div>

                  <div style={{ textAlign: "right" }}>
                    <Button
                      sx={{ mt: "10px" }}
                      size="small"
                      startIcon={<Add />}
                      onClick={addPosition}
                      variant="outlined"
                    >
                      LKW-Art hinzufügen
                    </Button>
                  </div>
                </div>
                <div className="extraFlex">
                  <LabelWrapper label="Takt">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        disabled={state.takt < 1}
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            takt: s.takt - 5,
                          }))
                        }
                      >
                        <RemoveCircleTwoTone />
                      </IconButton>
                      <Box sx={{ mx: "10px" }}>
                        {state.takt > 0
                          ? state.takt +
                            " min Abstand zwischen den einzelnen Beladungen"
                          : "alle gleichzeitig"}
                      </Box>
                      <IconButton
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            takt: s.takt + 5,
                          }))
                        }
                      >
                        <AddCircleTwoTone />
                      </IconButton>
                    </Box>
                  </LabelWrapper>
                  
                </div>
                <LabelWrapper label="Weitere Informationen">
                  <TextField
                    size="small"
                    fullWidth
                    value={state.comment}
                    placeholder="Weitere Informationen"
                    multiline
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        comment: (e.target as HTMLInputElement).value,
                      }))
                    }
                  />
                </LabelWrapper>
              </Fragment>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
