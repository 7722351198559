import { PossibleCalendarTypes } from "@/components/calendar/MainCalendar";
import BaseApiService from "@/shared/api/BaseApiService";
import request from "@/shared/api/request";

export default class ApiService extends BaseApiService {

  static getEntitiesWithFilter(entity: string, filter: {[field: string]: string|number|[string,number]}, v?: string) {
    let add = {};
    if (!!v){
      add = {v};
    }
    return () =>
      request({
        url: ("/entity/" + entity + "/"),
        method: "GET",
        params: { filter: btoa(JSON.stringify(filter)), ...add }
      });
  }


    static parseOrderConfirmation(file: File) {
        const data = new FormData();
        data.append("file", file);
        return request(
          {
            url: "/parseOrderConfirmation/",
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data'
              },
            data,
          }
        );
      }

      static sendOrder(jobId: string) {
        return () =>
          request({
            url: "/data/sendOrder/" + jobId,
            method: "POST",
            data: {}
          });
      }

      static searchMaterials() {
        return (data: {search: string}) =>
          request({
            url: "/data/searchMaterials/" + data.search,
            method: "GET",
          });
      }

      static changeCalendar() {
        return (data) =>
          request({
            url: "/data/changeCalendar",
            method: "POST",
            data
          });
      }

      static uploadFile() {
        return (file) => {
          const data = new FormData();
          data.append("file",file);
          return request({
            url: "/data/uploadImage",
            method: "POST",
            data,
            headers:{"Content-Type": "multipart/form-data"}
          });
        }
      }

      static autoBook() {
        return (id) => request({
            url: "/data/autoBooking/"+id,
            method: "POST",
          });
      }

      static getCalendar(type: PossibleCalendarTypes, reference: number, month: string, region: number) {
        return () =>
          request({
            url: "/data/calendar/" + type + "/"+reference+"/"+month+"/"+region,
            method: "GET",
          });
      }

      static getOpenRequests(region: number) {
        return () =>
          request({
            url: "/data/open/" +region,
            method: "GET",
          });
      }

      static getMap(region: number) {
        return () =>
          request({
            url: "/data/map/"+region,
            method: "GET",
          });
      }

      static getEntitiesByTag(entity: BackendEntity, tag: number, region: number) {
        return () =>
          request({
            url: "/entityByTag/" + entity + "/"+tag+ "/"+region,
            method: "GET",
          });
      }

}
