import {
  Add,
  PostAdd,
  Error as ErrorIcon,
  Visibility,
  Delete,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useMemo, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { StoffstromOrder } from "../Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { useDropzone } from "react-dropzone";
import ApiService from "@/api/ApiService";

const baseStyle = {
  flex: 1,
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  backgroundColor: "#eaeaea",
};

const acceptStyle = {
  backgroundColor: "rgb(223, 255, 205)",
};

const rejectStyle = {
  backgroundColor: "rgb(255, 205, 205)",
};

export default function AddStoffstromDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: StoffstromOrder) => void;
  OpenButton: any;
  initialState: StoffstromOrder;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [open, setOpen] = useState(false);

  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState(null);
  const [date, setDate] = useState("");
  const [id, setId] = useState(0);
  const [type, setType] = useState<StoffstromOrder["type"]>("OFFER");
  const [attachments, setAttachments] = useState<
    StoffstromOrder["attachments"]
  >([]);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const onDrop = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const names = acceptedFiles.map((x) => x.name);
      const responses = await Promise.all(
        acceptedFiles.map((file) => ApiService.uploadFile()(file))
      );
      setAttachments((a) => [
        ...a,
        ...responses.map((r, i) => ({
          filename: r.file,
          originalName: names[i],
        })),
      ]);
      setIsLoading(false);
      setIsError(false);
    } catch (e) {
      console.error(e);
      setIsError(true);
      setIsLoading(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ disabled: isLoading, onDrop, accept: { "*": [] } });

  const openAndSet = useCallback(() => {
    setAmount(!initialState ? "" : initialState.amount);
    setId(!initialState ? 0 : initialState.id);
    setAttachments(!initialState ? [] : initialState.attachments ?? []);
    setCategory(!initialState ? null : initialState.category);
    setComment(!initialState ? "" : initialState.comment);
    setDate(!initialState ? "" : initialState.date);
    setType(!initialState ? "OFFER" : initialState.type);
    setOpen(true);
  }, [initialState]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Stoffstromeintrag {id > 0 ? "bearbeiten" : "hinzufügen"}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Typ">
              <ToggleButtonGroup
                orientation="horizontal"
                exclusive
                value={type}
                onChange={(_, val) => val && setType(val)}
              >
                <ToggleButton value="OFFER">Verfügbares Material</ToggleButton>
                <ToggleButton value="REQUEST">Benötigtes Material</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Kategorie">
              <ManyToOneSelect<{ id: number; name: string }>
                size="small"
                entity={"stoffstromCategory"}
                showWithoutChoice
                autoSelect={false}
                displayFunction={(o) => (!!o ? o.name : "(unbekannt)")}
                currentId={category ?? 0}
                setId={(id) => setCategory(id || null)}
                fullWidth
                placeholder={"Kategorie"}
              />
            </LabelWrapper>
            <LabelWrapper label="Menge">
              <TextField
                size="small"
                fullWidth
                value={amount}
                placeholder="Menge"
                onChange={(e) =>
                  setAmount((e.target as HTMLInputElement).value)
                }
              />
            </LabelWrapper>

            <LabelWrapper label="Zeitraum">
              <TextField
                size="small"
                fullWidth
                value={date}
                placeholder="Zeitraum"
                onChange={(e) => setDate((e.target as HTMLInputElement).value)}
              />
            </LabelWrapper>

            <LabelWrapper label="Bilder/Dokumente">
              {attachments.map((x, i) => (
                <Box key={i} sx={{ display: "flex", alignItems: "center", mb: "10px", gap: "10px" }}>
                  <Box sx={{flex: 1, maxWidth: "310px", overflow: "hidden", textOverflow: "ellipsis"}}>{x.originalName}</Box>
                  <Button
                    href={
                      "https://bob.juchem-gruppe.de/backend/api/image/" +
                      x.filename
                    }
                    target="_blank"
                    startIcon={<Visibility />}
                    variant="outlined"
                  >
                    Ansehen
                  </Button>
                  <Button
                    onClick={() =>
                      setAttachments((a) => {
                        const x = a.slice();
                        x.splice(i, 1);
                        return x;
                      })
                    }
                    color="error"
                    startIcon={<Delete />}
                  >
                    Löschen
                  </Button>
                </Box>
              ))}
              <Paper variant="outlined" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isError ? (
                  <ErrorIcon color="error" />
                ) : isLoading ? (
                  <CircularProgress color="info" />
                ) : (
                  <Box sx={{ textAlign: "center", color: "#444" }}>
                    <PostAdd />
                    <Box>Dateien hier ablegen</Box>
                  </Box>
                )}
              </Paper>
            </LabelWrapper>

            <LabelWrapper label="Weitere Informationen">
              <TextField
                size="small"
                fullWidth
                value={comment}
                placeholder="Weitere Informationen"
                multiline
                onChange={(e) =>
                  setComment((e.target as HTMLInputElement).value)
                }
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!category || date.length === 0 || amount.length === 0}
              onClick={() => {
                save({
                  id,
                  deleted: false,
                  comment,
                  type,
                  category,
                  amount,
                  attachments,
                  date,
                });
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
