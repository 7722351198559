import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
} from "@mui/material";
import { useMemo } from "react";
import {
  ArrowForward,
  ArrowRight,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import { OrderPosition, SupplierOrder as SO } from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import dayjs from "dayjs";

const initialHeadCells = [
  {
    id: "article_number",
    label: "Artikelnummer",
    numeric: true,
    sortable: true,
    visible: false,
  },
  {
    id: "article_name",
    label: "Artikelname",
    numeric: false,
    sortable: true,
    visible: false,
  },
  {
    id: "plant",
    label: "Lieferwerk",
    numeric: false,
    sortable: true,
    visible: false,
  },
  {
    id: "delivery_type",
    label: "Zufuhrart",
    numeric: false,
    sortable: true,
    visible: false,
  },
  {
    id: "amount",
    label: "Menge",
    numeric: true,
    sortable: true,
    visible: false,
  },
  {
    id: "price",
    label: "Preis",
    numeric: true,
    sortable: true,
    visible: false,
  },
];

export default function SupplierOrder({
  deleteOrder,
  onSelect = null,
  so,
}: {
  deleteOrder: () => void | null;
  onSelect: ((position: OrderPosition) => void) | null;
  so: SO;
}) {
  const [data, headCells] = useMemo(() => {
    const outcells = initialHeadCells.slice();
    const data = [];

    for (const pos of so.positions) {
      const here = {
        self: pos,
        article_number: pos.article.number,
        article_name: pos.article.name,
        plant: pos.article.plantName,
        delivery_type: pos.deliveryType.name,
        amount: pos.amount,
        price:
          !!pos.price &&
          (typeof pos.price == "number" || !isNaN(parseFloat(pos.price)))
            ? parseFloat(pos.price).toFixed(2).replace(/\./, ",") + " €"
            : "",
      };
      for (let i = 0; i < outcells.length; i++) {
        if (outcells[i].visible) continue;
        outcells[i].visible =
          !!here[outcells[i].id] && here[outcells[i].id] !== "";
      }

      data.push(here);
    }

    return [data, outcells.filter((x) => x.visible)];
  }, [so]);



  return (
    <Accordion disableGutters>
      <AccordionSummary
        sx={{
          background: "#002169",
          color: "white",
          borderBottom: "1px solid rgba(255,255,255,0.5)",
        }}
        expandIcon={<ExpandMore sx={{ color: "white" }} />}
      >
        <Typography
          sx={{
            color: "#aaa",
            minWidth: "100px",
            mr: "20px",
          }}
        >
          {so.number}
        </Typography>
        <Typography sx={{ flexShrink: 0, flex: 1 }}>
          {so.supplier.name}
        </Typography>
        <Typography sx={{ textAlign: "right", mx: "20px", color: "#aaa" }}>
          {dayjs(so.validFrom).format("DD.MM.YYYY")} –{" "}
          {dayjs(so.validUntil).format("DD.MM.YYYY")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)", p: 0 }}
      >
        <EnhancedTable
          initialRows={10}
          buttons={
            !!onSelect
              ? (row) => (
                  <IconButton onClick={() => onSelect(row.self)} size="small">
                    <ArrowForward />
                  </IconButton>
                )
              : (row) => null
          }
          type=""
          noEmptyCells
          initialSort="article_name"
          headCells={headCells}
          rows={data}
          extraButtons={
            so.externalId === "" && !!deleteOrder ? (
              <Button
                variant="outlined"
                color="error"
                onClick={deleteOrder}
                startIcon={<Delete />}
              >
                Löschen
              </Button>
            ) : null
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}
