import { CalendarContext } from "@/components/shared/VerticalCalendar";
import dayjs from "dayjs";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

const preventDefault = (e) => e.preventDefault();

export default function JobLine({
  line,
  onSelect,
  startAdd,
  selectedDate,
  jobs,
  changeDates,
}) {


  const view = useContext(CalendarContext);
  const [startPageX, setStartPageX] = useState<number | null>(null);
  const [addMap, setAddMap] = useState<{ [date: string]: string }>({});

  const onSelectDate = (e) => {
 
    const date = view.calStart
      .add(
        Math.floor((e.clientX - e.target.getBoundingClientRect().left) / view.zoom),
        "days"
      )
      .format("YYYY-MM-DD");
    onSelect(date, !!view?.zoom && view?.zoom < 25);
  };

  const onSelectExistingDate = (e) => {
    onSelect(e.target.dataset.date);
  };

  const jobkeys = useMemo(
    () =>
      Object.values(jobs)
        .filter((j) => !j.deleted)
        .map((x) => x.date),
    [jobs]
  );

  useLayoutEffect(() => {
    setAddMap({});
  }, [jobkeys]);

  const dragstart = useCallback(
    (e) => {
      e.dataTransfer.setDragImage(blankImage, 0, 0);
      e.dataTransfer.effectAllowed = "move";
      e.target.style.cursor = "ew-resize !important";
      e.target.classList.add("dragging");
      setStartPageX(e.pageX);
    },
    [setStartPageX]
  );

  const calculateAddMap = useCallback(
    (date: string, add: number) => {
      const newAddMap: { [date: string]: string } = {};
      if (add !== 0) {
        const dates = Object.values(jobs)
          .filter((j) => !j.deleted)
          .map((x) => x.date)
          .sort(
            add > 0
              ? (a, b) => a.localeCompare(b)
              : (a, b) => b.localeCompare(a)
          );

        newAddMap[date] = dayjs(date, "YYYY-MM-DD")
          .add(add, "days")
          .format("YYYY-MM-DD");
        let lastDate = newAddMap[date];
        for (const c of dates) {
          if ((add > 0 && c <= date) || (add < 0 && c >= date)) continue;
          if ((add > 0 && lastDate < c) || (add < 0 && lastDate > c)) break;
          let newDate = dayjs(lastDate, "YYYY-MM-DD").add(
            add > 0 ? 1 : -1,
            "day"
          );
          while (
            newDate.isoWeekday() > 5 &&
            !dates.includes(newDate.format("YYYY-MM-DD"))
          ) {
            newDate = newDate.add(add > 0 ? 1 : -1, "day");
          }
          newAddMap[c] = newDate.format("YYYY-MM-DD");
          lastDate = newAddMap[c];
        }
      }
      setAddMap(newAddMap);
      return newAddMap;
    },
    [jobkeys, setAddMap]
  );

  const mouseMove = useCallback(
    (e) => {
      if (e.pageX === 0 || !startPageX) return;
      const factor = view.zoom < 15 ? 7 : 1;
      calculateAddMap(
        e.target.dataset.date,
        Math.round((e.pageX - startPageX) / view.zoom / factor) * factor
      );
    },
    [calculateAddMap, view.zoom, startPageX]
  );

  const mouseUp = useCallback(
    (e) => {
      if (e.pageX === 0 || !startPageX) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const addMap = calculateAddMap(
        e.target.dataset.date,
        Math.round((e.pageX - startPageX) / view.zoom / factor) * factor
      );
      e.target.classList.remove("dragging");
      changeDates(addMap, e.target.dataset.date);

      setStartPageX(null);
    },
    [startPageX, calculateAddMap, view.zoom, setStartPageX]
  );

  const days = [];
  for (let date in jobs) {
    if (jobs[date].deleted) continue;
    const dd =
      startAdd !== 0
        ? dayjs(date, "YYYY-MM-DD").add(startAdd, "days")
        : dayjs(addMap[date] ?? date, "YYYY-MM-DD");
    days.push(
      <div
        draggable={true}
        onDragOver={preventDefault}
        onDragStart={dragstart}
        onDrag={mouseMove}
        onDragEnd={mouseUp}
        onClick={onSelectExistingDate}
        className={"vc-date" + (date === selectedDate ? " selected" : "")}
        data-date={date}
        key={"phase" + date}
        style={{
          background:
            jobs[date].status === "PLANNING"
              ? date === selectedDate
                ? "#79B4DF"
                : "#99D4FF"
              : jobs[date].status === "RAW"
              ? date === selectedDate
                ? "#86DF79"
                : "#A6FF99"
              : date === selectedDate
              ? "#DF7979"
              : "#FF9999",
          display: "flex",
          justifyContent: "space-between",
          gridRow: line,
          gridColumn: dd.diff(view.calStart, "days") + 2,
        }}
      />
    );
  }

  return [
    <div
      className="vc-jobline"
      onClick={onSelectDate}
      key={"hlx" + line}
      style={{
        gridRow: line,
        gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
      }}
    ></div>,
    days,
  ];
}
