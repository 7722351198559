import { Avatar, Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";

export default function ErrorIndicator() {
  return (
    <Box
      sx={{
        display: "flex", padding: 1, flexDirection: "column", alignItems: "center"
      }}
    >
      <Avatar variant="rounded" sx={{ bgcolor: red[500] }}>
        <ClearTwoToneIcon />
      </Avatar>
      <Typography sx={{marginTop: 0.5}} variant="subtitle2" display="block">
            Es ist ein Fehler aufgetreten.
      </Typography>
    </Box>
  );
}
