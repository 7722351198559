import {
  Paper,
  Button,
  Dialog,
  DialogContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import { useCallback, useState } from "react";
import OrderConfirmationParser, {
  ParsedState,
} from "../dialogs/OrderConfirmationParser";
import { AccessAlarm, Add, ExpandMore } from "@mui/icons-material";
import { ProjectState, SubcontractorOrder as SO } from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import SupplierOrder from "../elements/SupplierOrder";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import AddSubcontractorDialog from "../dialogs/AddSubDialog";
import SubcontractorOrder from "../elements/SubcontractorOrder";

const headCells = [
  {
    id: "article_number",
    label: "Artikelnummer",
    numeric: true,
    sortable: true,
  },
  {
    id: "article_name",
    label: "Artikelname",
    numeric: false,
    sortable: true,
  },
  {
    id: "plant",
    label: "Lieferwerk",
    numeric: false,
    sortable: true,
  },
  {
    id: "delivery_type",
    label: "Zufuhrart",
    numeric: false,
    sortable: true,
  },
  {
    id: "amount",
    label: "Menge",
    numeric: true,
    sortable: true,
  },
  {
    id: "price",
    label: "Preis",
    numeric: true,
    sortable: true,
  },
];

export default function ArticlePage({
  project,
  setField,
}: {
  project: ProjectState;
  setField: (changes: Partial<ProjectState>) => void;
}) {
  const deleteOrder = useCallback(
    (index: number) => {
      setField({
        supplierOrders: project.supplierOrders.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
        })),
      });
    },
    [project]
  );

  const deleteSubOrder = useCallback(
    (index: number) => {
      setField({
        subcontractorOrders: project.subcontractorOrders
          .slice()
          .map((so, i) => ({
            ...so,
            deleted: !!so.deleted || i === index,
          })),
      });
    },
    [project]
  );

  const save = useCallback(
    (order: ParsedState & { valid_from: string; valid_until: string }) => {
      const supplierOrders = project.supplierOrders.slice();
      supplierOrders.push({
        id: 0,
        number: order.document_number,
        validFrom: order.valid_from,
        externalId: "",
        validUntil: order.valid_until,
        supplier: {
          id: 0,
          number: "",
          name: order.supplier_name,
          contact: "",
          phone: "",
          email: "",
        },
        positions: order.articles
          .filter((x) => !x.deleted)
          .map((article) => ({
            id: 0,
            article: {
              id: 0,
              number: article.article_number,
              name: article.article_name,
              plantName: article.plant,
              supplierName: order.supplier_name,
            },
            deliveryType: {
              id: 0,
              name: article.delivery_type,
            },
            price: parseFloat(article.price.replace(/,/, ".").trim()) || null,
            amount: parseInt(article.amount.replace(/,/, ".").trim()) || null,
          })),
      });
      setField({
        supplierOrders,
      });
    },
    [project]
  );

  const saveSub = useCallback(
    (subOrder: SO) => {
      const subcontractorOrders = project.subcontractorOrders.slice();
      subcontractorOrders.push(subOrder);
      setField({
        subcontractorOrders,
      });
    },
    [project]
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <TopBar>
        <OrderConfirmationParser save={save} />
        <AddSubcontractorDialog save={saveSub} />
      </TopBar>
      <MainContainer paperless>
        {project.supplierOrders.length > 0 &&
        project.subcontractorOrders.length > 0 ? (
          <Typography variant="h6" gutterBottom>
            Artikel
          </Typography>
        ) : null}
        {project.supplierOrders.map((so, i) =>
          so.deleted ? null : (
            <SupplierOrder so={so} deleteOrder={() => deleteOrder(i)} key={i} />
          )
        )}
        {project.supplierOrders.length > 0 &&
        project.subcontractorOrders.length > 0 ? (
          <Typography sx={{ mt: 2 }} variant="h6" gutterBottom>
            Subunternehmer
          </Typography>
        ) : null}
        {project.subcontractorOrders.map((so, i) =>
          so.deleted ? null : (
            <SubcontractorOrder
              so={so}
              deleteOrder={() => deleteSubOrder(i)}
              key={i}
            />
          )
        )}
      </MainContainer>
    </Box>
  );
}
