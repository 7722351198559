import { BackendEntity } from "@/shared/types";
import request from "./request";
import { AxiosRequestConfig } from "axios";

export default class BaseApiService {
  static loginCheck(params: { email: string; password: string }) {
    return request(
      {
        url: "/login_check",
        method: "POST",
        data: {
          email: params.email,
          password: params.password,
        },
      },
      false
    );
  }

  static getRequestsAndOrders() {
    return (params: any) => {
      const [_key, date, region ] = params.queryKey;
      return request({
        url: "/data/requestsAndOrders/" + date +"/"+region,
        method: "GET",
      });
    };
  }
  static getTrucksWithStatus() {
    return (params: any) => {
      const [_key, date, region ] = params.queryKey;
      return request({
        url: "/data/trucksWithStatus/" + date + "/"+region,
        method: "GET",
      });
    };
  }

  static changePassword() {
    return (data: any) => {
      return request({
        url: "/data/changePassword",
        method: "POST",
        data
      });
    }
  }


  static refreshToken(refreshToken: string) {
    const params = new URLSearchParams();
    params.append("refresh_token", refreshToken);
    return request(
      {
        url: "/token/refresh",
        method: "POST",
        data: params,
      },
      false
    );
  }

  static editEntity(entity: BackendEntity, id: number, v?: string) {
    let add = {};
    if (!!v){
      add = {params: {v}};
    }
    return (data: any) =>
      request({
        url: "/entity/" + entity + "/" + id,
        method: "PUT",
        data,
        ...add
      });
  }

  static createEntity(entity: BackendEntity, authenticated: boolean = true, params = {}) {
    return (data: any) =>
      request({
        url: "/entity/" + entity + "/",
        method: "POST",
        data,
        params
      }, authenticated);
  }

  static getEntities(entity: BackendEntity, region?: number, v?: string) {
    let add = {};
    if (!!v){
      add = {params: {v}};
    }
    return () =>
      request({
        url: !!region ? ("/entitiesByRegion/" + entity + "/"+region) : ("/entity/" + entity + "/"),
        method: "GET",
        ...add
      });
  }

  static getEntitiesWithFilter(entity: BackendEntity, filter: {[field: string]: string|number|[string,number]}, v?: string) {
    let add = {};
    if (!!v){
      add = {v};
    }
    return () =>
      request({
        url: ("/entity/" + entity + "/"),
        method: "GET",
        params: { filter: JSON.stringify(filter), ...add }
      });
  }

  static getEntity(entity: BackendEntity, id: number, authenticated: boolean = true, v: string|false = false ) {
    let add = {};
    if (!!v){
      add = {params: {v}};
    }
    return () =>
      request({
        url: "/entity/" + entity + "/" + id,
        method: "GET",
        ...add
      }, authenticated);
  }

  static autoNumber(circle: string) {
    return () =>
      request({
        url: "/data/getNumber/" + circle,
        method: "GET",
      });
  }

  static deleteEntity(entity: BackendEntity, id: number) {
    return (id2: number) =>
      request({
        url: "/entity/" + entity + "/" + (id === 0 ? id2 : id),
        method: "DELETE",
      });
  }

  static geocode() {
    return (data: {search: string}) =>
      request({
        baseURL: "https://geocode.search.hereapi.com/v1",
        url: "/discover?apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k&at=49.76861,7.33583&q=" + data.search,
        method: "GET",
      }, false);
  }

}
