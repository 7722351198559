import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Key,
} from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import CustomListItem from "./CustomListItem";
import { Button, Typography } from "@mui/material";

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  minHeight: 48,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  withImage?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, withImage }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({
  setAuthenticated,
  elements,
  menu,
  name,
  image = false,
  passwordLink = "/password",
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open} withImage={!!image}>
        <Toolbar
          variant="dense"
          sx={!!image && !open ? { paddingLeft: "0 !important" } : {}}
        >
          {menu.length > 0 ? (
            !!image && !open ? (
              <Box
              onClick={handleDrawerOpen}
                sx={{
                  cursor: "pointer",
                  backgroundImage: "url(" + image + ")",
                  height: "40px",
                  width: "65px",
                  mr: "20px",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { visibility: "hidden" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            )
          ) : null}
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {name}
          </Typography>
          <Box sx={{ flex: 1 }} />
          {elements}
          <Button color="inherit" onClick={() => setAuthenticated(false)}>
            Abmelden
          </Button>
        </Toolbar>
      </AppBar>
      {menu.length > 0 ? (
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.dark,
              color: "red",
            },
          }}
          open={open}
        >
          <DrawerHeader>
            <IconButton sx={{ color: "white" }} onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {menu.map((x) => (
              <CustomListItem key={x.to} {...x} open={open} />
            ))}
          </List>
          <Box sx={{ flex: 1 }} />
          <CustomListItem
            open={open}
            to={passwordLink}
            Icon={Key}
            text={"Passwort ändern"}
          />
        </Drawer>
      ) : null}
      <Box
        component="main"
        id="mainComponent"
        sx={{
          flexGrow: 1,
          p: 0,
          flexDirection: "column",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
