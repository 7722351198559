import {
  Add,
  AddCircleTwoTone,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { initialJob, ResourceOrder } from "../../planning/Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { usePersistantStore } from "@/stores/global";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

const fixSizes = (refs: ResourceOrder["references"], amount: number) =>
  refs.length === amount
    ? refs
    : refs.length > amount
    ? refs.slice(0, refs.length)
    : refs.concat(new Array(amount - refs.length).fill(null));

const initialState = (type, reference, date) => ({
  id: 0,
  deleted: false,
  amount: 1,
  tag: reference || null,
  comment: "",
  type: type === "CREWS" ? "CREW" : type === "RESOURCES" ? "RESOURCE" : "HUMAN",
  references: [],
  job: {
    id: 0,
    date,
    uuid: self.crypto.randomUUID(),
    deleted: false,
    phase: {
      id: 0,
      name: "Baustelle",
      type: "sonstiges",
      uuid: self.crypto.randomUUID(),
      plannedStart: date,
      plannedEnd: date,
      project: 0,
    },
  },
});

export default function AddFastResourceDialog({
  date,
  reference,
  type,
  close,
}) {
  const region = usePersistantStore((store) => store.region);
  const [state, setState] = useState(initialState(type, reference, date));

  useEffect(
    () => setState(initialState(type, reference, date)),
    [type, reference, date]
  );
  const queryClient = useQueryClient();

  const saveMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.createEntity("resourceOrder", true, {
      version: "fastbooking",
    }),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      close();
    },
  });

  const jobQuery = useQuery<any[], AxiosResponse>({
    queryKey: ["getJobByDate", state.job.phase.id, date],
    enabled: state.job.phase.id > 0,
    queryFn: ApiService.getEntitiesWithFilter("projectJob", {
      phase: ["projectPhase", state.job.phase.id],
      date: ["%DATETIME", date],
    }),
  });

  const valid = !!state.job.phase.project && !jobQuery.isLoading;

  useEffect(() => {
    if (jobQuery.status !== "success" || jobQuery.data.length === 0){
      setState((s) => ({
        ...s,
        job: { ...s.job, id: 0 },
      }));
    } else {
      setState((s) => ({
        ...s,
        job: { ...s.job, id: jobQuery.data[0].id ?? 0 },
      }));
    }
  }, [jobQuery.status])

  return (
    <Dialog open={!!date} maxWidth="lg" fullWidth onClose={close}>
      <Fragment>
        <DialogTitle
          sx={{
            p: 1,
            px: 2,
            background: "#002169",
            color: "white",
            borderBottom: "1px solid rgba(255,255,255,0.5)",
          }}
        >
          Schnellauftrag für {dayjs(date).format("ddd, D. MMMM YYYY")}
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LabelWrapper label="Projekt">
            <ManyToOneSelect
              showWithoutChoice
              remoteFilter={
                !!region ? { region: ["region", region] } : undefined
              }
              displayFunction={(o) => o.name + " (" + o.number + ")"}
              remoteFilterReady={true}
              placeholder="Bitte wählen..."
              entity={"project"}
              currentId={state.job.phase.project ?? 0}
              setId={(project) =>
                setState((s) => ({
                  ...s,
                  job: { ...s.job, id: 0, phase: { ...s.job.phase, id: 0, project } },
                }))
              }
            />
          </LabelWrapper>
          {!!state.job.phase.project ? (
            <LabelWrapper label="Bauphase">
              <ManyToOneSelect
                showWithoutChoice
                remoteFilter={{ project: ["project", state.job.phase.project] }}
                remoteFilterReady={!!state.job.phase.project}
                placeholder="Leer lassen zum Erstellen einer neuen Bauphase"
                entity={"projectPhase"}
                currentId={state.job.phase.id ?? 0}
                setId={(id) =>
                  setState((s) => ({
                    ...s,
                    job: { ...s.job, phase: { ...s.job.phase, id } },
                  }))
                }
              />
            </LabelWrapper>
          ) : null}
          <LabelWrapper label="Anzahl">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disabled={state.amount <= 1}
                onClick={() =>
                  setState((s) => ({
                    ...s,
                    amount: s.amount - 1,
                  }))
                }
              >
                <RemoveCircleTwoTone />
              </IconButton>
              <Box sx={{ fontSize: "20px", mx: "10px" }}>{state.amount}</Box>
              <IconButton
                onClick={() =>
                  setState((s) => ({
                    ...s,
                    amount: s.amount + 1,
                  }))
                }
              >
                <AddCircleTwoTone />
              </IconButton>
            </Box>
          </LabelWrapper>
          <LabelWrapper label="Weitere Informationen">
            <TextField
              size="small"
              fullWidth
              value={state.comment}
              placeholder="Weitere Informationen"
              multiline
              onChange={(e) =>
                setState((s) => ({
                  ...s,
                  comment: (e.target as HTMLInputElement).value,
                }))
              }
            />
          </LabelWrapper>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
          <Button onClick={close}>Abbrechen</Button>
          <LoadingButton
            variant="outlined"
            color="primary"
            disabled={!valid}
            loading={saveMutation.isPending}
            onClick={() => {
              saveMutation.mutate(state);
              close();
            }}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Fragment>
    </Dialog>
  );
}
