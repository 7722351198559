import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { AxiosResponse } from "axios";
import {
  AddCircleOutlineTwoTone,
  ArrowRight,
  DeleteTwoTone,
} from "@mui/icons-material";
import {
  User as BitumenUser,
  Right as BitumenRight,
  WithId,
} from "../types_bitumen";
import { User as TransUser, Right as TransRight } from "../types";
import useRights from "../api/useRights";
import BaseApiService from "../api/BaseApiService";
import { TopBar } from "../structure/TopBar";
import { validate } from "../api/validate";
import { MainContainer } from "../structure/MainContainer";
import ErrorIndicator from "../dialogs/ErrorIndicator";
import ManyToOneSelect from "./ManyToOneSelect";
import ManyToManySelect from "./ManyToManySelect";
import ErrorDialog from "../dialogs/ErrorDialog";

type UniversalUser = BitumenUser | TransUser;
type Right = BitumenRight | TransRight;

const checkValidity = (
  userRights: UniversalUser["rights"],
  allRights: { [x: string]: any }
) => {
  //track: , , selectionConstraints,

  const duplicateKeys = new Set();
  const duplicateGroups = new Set();
  const usedKeys = new Set();
  const usedGroups = new Set();
  const selectionConstraints: { rights: string[]; neededBy: string }[] = [];
  const validKeys = new Set();

  const issues = [];

  for (let right of userRights) {
    if (!!right.deleted) continue;
    if (!right.right) {
      issues.push("Leere Berechtigung gefunden.");
      continue;
    }
    if (!(right.right in allRights)) continue;
    const settings = allRights[right.right];
    if (settings.type === "Boolean" && right.intVal === null) {
      issues.push(
        "Fehlender Wert bei der Berechtigung '" + settings.description + "'."
      );
    } else if (
      settings.type === "ManyToMany" &&
      (!right.stringVal ||
        right.stringVal
          .split(",")
          .filter((x) => !isNaN(parseInt(x)) && parseInt(x) > 0).length === 0)
    ) {
      issues.push(
        "Fehlende zugehörige Einheiten bei der Berechtigung '" +
          settings.description +
          "'."
      );
    } else if (
      settings.type === "ManyToOne" &&
      (!right.stringVal ||
        isNaN(parseInt(right.stringVal)) ||
        parseInt(right.stringVal) === 0)
    ) {
      issues.push(
        "Fehlende zugehörige Einheit bei der Berechtigung '" +
          settings.description +
          "'."
      );
    } else {
      validKeys.add(right.right);
    }

    if (usedKeys.has(right.right) && !duplicateKeys.has(right.right)) {
      issues.push(
        "Berechtigung '" + settings.description + "' ist mehrfach vorhanden."
      );
      duplicateKeys.add(right.right);
    }

    if (
      settings.optionGroup.length > 0 &&
      usedGroups.has(settings.optionGroup) &&
      !duplicateGroups.has(settings.optionGroup)
    ) {
      issues.push(
        "Es darf nur eine Berechtigung der Art '" +
          settings.optionGroup +
          "' vorhanden sein."
      );
      duplicateGroups.add(settings.optionGroup);
    }

    if (settings.optionGroup.length > 0) usedGroups.add(settings.optionGroup);

    if (settings.selectionConstraint.length > 0) {
      selectionConstraints.push({
        rights: settings.selectionConstraint
          .split(",")
          .filter((x) => x.length > 0),
        neededBy: settings.description,
      });
    }

    usedKeys.add(right.right);
  }

  if (usedKeys.size === 0){
    issues.push("Es wurde noch keine Berechtigung oder Rolle gewählt.");
  }

  for (const { rights, neededBy } of selectionConstraints) {
    let isFilled = false;
    for (const right of rights){
      if (validKeys.has(right)) isFilled = true;
    }
    if (isFilled) continue;
    issues.push(
      "Die Berechtigung '" +
        neededBy +
        (rights.length === 1 ? "' erfordert die fehlende Berechtigung '" : "' erfordert eine dieser Berechtigungen: '") +
        rights.map(r => allRights[r].description ?? "???").join("', '") +
        "'."
    );
  }
  return issues;
};

export default function EditUsers({
  setPageTitle,
}: {
  setPageTitle: (title: string) => void;
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { rawId } = useParams();

  const rights = useRights();
  if (!("USERADMIN" in rights)) return <Navigate replace to="/" />;

  const id = !rawId ? 0 : parseInt(rawId);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [editPassword, setEditPassword] = useState(true);
  const [pW, setPW] = useState("");
  const [userRights, setUserRights] = useState<UniversalUser["rights"]>([]);

  const setField = (
    id: number,
    changes: Partial<UniversalUser["rights"][0]>
  ) => {
    const nur = userRights.slice();
    nur[id] = { ...nur[id], ...changes };
    setUserRights(nur);
  };

  useEffect(
    () => setPageTitle(id > 0 ? "Benutzer bearbeiten" : "Neuer Benutzer"),
    []
  );

  const [validationMessages, setValidationMessages] = useState<String[]>([]);

  const [shouldFetch, setShouldFetch] = useState(id > 0);

  const query = useQuery<UniversalUser, AxiosResponse>({
    queryKey: ["getUser", id],
    queryFn: BaseApiService.getEntity("user", id),
    enabled: shouldFetch
  });

  useEffect(() => {
    if (!query.isSuccess || !query.data) return;
      setShouldFetch(false);
      setName(query.data.name);
      setEditPassword(false);
      setPW(query.data.pW);
      setEmail(query.data.email);
      setUserRights(query.data.rights);
  },[query.isSuccess]);


  const rightsQuery = useQuery<Right[], AxiosResponse>({
    queryKey: ["getRights"],
    queryFn: BaseApiService.getEntities("right"),
  });

  const allRights: { [name: string]: Right } = useMemo(() => {
    if (!rightsQuery.data || rightsQuery.data.length === 0) return {};
    const out: { [name: string]: Right } = {};
    for (const o of rightsQuery.data) {
      if (
        o.visibilityConstraint.length > 0 &&
        !(o.visibilityConstraint in rights)
      )
        continue;
      out[o.name] = o;
    }
    return out;
  }, [rightsQuery.data]);

  const mutation = useMutation<any, AxiosResponse, UniversalUser>(
    
    {
      mutationFn: id > 0
      ? BaseApiService.editEntity("user", id)
      : BaseApiService.createEntity("user"),
      onSuccess: (answer) => {
        queryClient.invalidateQueries({ queryKey: ["getUsers"] });
        if (id > 0)
          queryClient.invalidateQueries({ queryKey: ["getUser", id] });
        navigate(-1 /*"/settings/users"*/);
      },
    }
  );

  const valid = checkValidity(userRights, allRights);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      <TopBar>
        <LoadingButton
          onClick={() =>
            validate(
              { name, email, rights: userRights, pW },
              mutation.mutate,
              setValidationMessages
            )
          }
          loading={mutation.status === "pending"}
          variant="text"
          disabled={
            (!query.isError && shouldFetch) || query.isError || valid.length > 0
          }
          startIcon={<SaveTwoToneIcon />}
        >
          Speichern
        </LoadingButton>
        <Button
          onClick={() => navigate(-1 /*"/settings/users"*/)}
          variant="text"
          startIcon={<CancelTwoToneIcon />}
        >
          Abbrechen
        </Button>
      </TopBar>
      <MainContainer>
        {(!query.isError && shouldFetch) || rightsQuery.isLoading ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : query.isError || rightsQuery.isError ? (
          <ErrorIndicator />
        ) : (
          <Box sx={{ padding: 2 }}>
            {validationMessages.length ? (
              <Alert severity="error">
                <AlertTitle>Fehlende Daten</AlertTitle>
                {validationMessages.map((message, key) => (
                  <div key={key}>{message}</div>
                ))}
              </Alert>
            ) : null}
            <TextField
              label="Name"
              fullWidth
              margin="dense"
              value={name}
              onChange={(e) => setName((e.target as HTMLInputElement).value)}
            />
            <TextField
              label="E-Mail"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
            />
            <Box sx={{ display: "flex", columnGap: "10px", mt: "10px" }}>
              {id > 0 ? (
                <ToggleButtonGroup
                  value={editPassword}
                  exclusive
                  onChange={(_, x) => {
                    if (!x) setPW(query.data?.pW ?? "");
                    else setPW("");
                    setEditPassword(x);
                  }}
                >
                  <ToggleButton value={false}>
                    Passwort&nbsp;behalten
                  </ToggleButton>
                  <ToggleButton value={true}>Passwort&nbsp;setzen</ToggleButton>
                </ToggleButtonGroup>
              ) : null}
              {editPassword ? (
                <TextField
                  label="Passwort"
                  fullWidth
                  margin="none"
                  value={pW}
                  type="password"
                  onChange={(e) => setPW((e.target as HTMLInputElement).value)}
                />
              ) : null}
            </Box>
            <Typography variant="h6" sx={{ mt: "10px" }} gutterBottom>
              Berechtigungen
            </Typography>
            <Box sx={{ border: "1px solid #aaa", borderRadius: "5px" }}>
              <Box sx={{ p: "10px", borderBottom: "1px solid #aaa" }}>
                {userRights.map((u, i) =>
                  !u.deleted && (!u.right || u.right in allRights) ? (
                    <Box
                      key={i}
                      sx={{ display: "flex", columnGap: "10px", mb: "10px" }}
                    >
                      <FormControl sx={{ minWidth: "25%" }}>
                        <InputLabel id={"label" + i}>Berechtigung</InputLabel>
                        <Select
                          labelId={"label" + i}
                          value={u.right}
                          label="Berechtigung"
                          onChange={(e) => {
                            setField(i, {
                              right: e.target.value as string,
                              stringVal: null,
                              intVal:
                                allRights[e.target.value].type === "Boolean"
                                  ? 0
                                  : null,
                            });
                          }}
                        >
                          {Object.values(allRights)
                            .sort((a, b) =>
                              a.ordering.localeCompare(b.ordering)
                            )
                            .map((x) => (
                              <MenuItem key={x.name} value={x.name}>
                                {x.description}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {!allRights[u.right] ? null : allRights[u.right].type ===
                        "ManyToOne" ? (
                        <ManyToOneSelect<{ name: string } & WithId>
                          sx={{ mt: 0 }}
                          key={allRights[u.right].entity}
                          entity={allRights[u.right].entity}
                          currentId={!u.stringVal ? 0 : parseInt(u.stringVal)}
                          setId={(val) => setField(i, { stringVal: "" + val })}
                          label={"Zugehörige Einheit"}
                        />
                      ) : allRights[u.right].type === "ManyToMany" ? (
                        <ManyToManySelect<{ name: string } & WithId>
                          sx={{ mt: 0, flex: 1 }}
                          key={allRights[u.right].entity}
                          entity={allRights[u.right].entity}
                          currentIds={
                            !u.stringVal
                              ? []
                              : u.stringVal.split(",").map((x) => parseInt(x))
                          }
                          setIds={(val) =>
                            setField(i, { stringVal: val.join(",") })
                          }
                          label={"Zugehörige Einheiten"}
                        />
                      ) : allRights[u.right].type === "Boolean" ? (
                        <Checkbox
                          checked={u.intVal === 1}
                          onChange={(e) =>
                            setField(i, { intVal: e.target.checked ? 1 : 0 })
                          }
                        />
                      ) : (
                        "Fehler"
                      )}
                      <IconButton
                        sx={{ flexShrink: 1, alignSelf: "center" }}
                        onClick={() => setField(i, { deleted: true })}
                      >
                        <DeleteTwoTone />
                      </IconButton>
                    </Box>
                  ) : null
                )}
                <Button
                  sx={{ mt: "4px" }}
                  onClick={() => {
                    const nur = userRights.slice();
                    nur.push({
                      id: 0,
                      right: null,
                      intVal: null,
                      stringVal: null,
                    });
                    setUserRights(nur);
                  }}
                  variant="contained"
                  startIcon={<AddCircleOutlineTwoTone />}
                >
                  Berechtigung hinzufügen
                </Button>
              </Box>
              <Alert severity={valid.length > 0 ? "warning" : "success"}>
                <AlertTitle>
                  {valid.length > 0 ? "Ungültig" : "Gültig"}
                </AlertTitle>
                {valid.map((x, i) => (
                  <Box sx={{ display: "flex", alignItems: "center" }} key={i}>
                    <ArrowRight />
                    {x}
                  </Box>
                ))}
              </Alert>
            </Box>
          </Box>
        )}
      </MainContainer>
      <ErrorDialog
        forceOpen={
          (query.isError && query.error.status === 401) ||
          (mutation.isError && mutation.error.status === 401) ||
          (rightsQuery.isError && rightsQuery.error.status === 401)
        }
      />
    </Box>
  );
}
