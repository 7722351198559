import EmptyListIndicator from "@/shared/dialogs/EmptyListIndicator";
import ErrorDialog from "@/shared/dialogs/ErrorDialog";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { Add, Edit } from "@mui/icons-material";
import { Box, Button, ButtonGroup, CircularProgress, IconButton } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import definitions, { SingleDefinition } from "./definitions";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { useEffect, useMemo } from "react";
import ApiService from "@/api/ApiService";
import { useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import DeleteButton from "@/shared/dialogs/DeleteButton";

export default function SettingsList({}) {
  const { type } = useParams();
  const navigate = useNavigate();
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  const region = usePersistantStore((state) => state.region);

  if (!type) return <Navigate replace to="/" />;

  const definition = definitions[type] as SingleDefinition;

  useEffect(() => {
    setPageTitle(definition.title);
  }, [definition.title]);

  const query = useQuery<any[], AxiosResponse>({
    queryKey: definition.regionalized
      ? ["get" + type[0].toUpperCase() + type.slice(1) + "s", region]
      : ["get" + type[0].toUpperCase() + type.slice(1) + "s"],
    queryFn: definition.regionalized
      ? ApiService.getEntitiesWithFilter(type, { region: ["region", region] }, "settings")
      : ApiService.getEntities(type, 0, "settings"),
  });

  const { rowFormatterFactory, headCells, namedEntities } = useMemo(() => {
    const headCells = [];
    const namedEntities = [];
    const rowFormatterFactoryData: [string,string,any][] = [];
    for (let field of definition.fields) {
      if (!field.table) continue;
      let prefix = false;
      if (field.type === "ManyToOne") {
        namedEntities.push(field.relation);
        prefix = true;
        rowFormatterFactoryData.push(["relation",field.id, field.relation]);
      } else if (field.type === "ManyToMany") {
        if (typeof field.relation !== "string") throw "Function based relation not allowed in table";
        namedEntities.push(field.relation);
        prefix = true;
        rowFormatterFactoryData.push(["relationList",field.id, field.relation]);
      } else if (field.type === "boolean") {
        prefix = true;
        rowFormatterFactoryData.push(["enum",field.id, {true: "Ja", false: "Nein"}]);
      } else if (field.type === "enum") {
        prefix = true;
        rowFormatterFactoryData.push(["enum",field.id, field.values]);
      }
      headCells.push({
        id: (prefix ? "_" : "") + field.id,
        label: field.title,
        numeric: false,
        sortable: true,
      });
    }
    const rowFormatterFactoryFactory = function (data: [string,string,any][]) {
      return function (names: { [type: string]: Map<number, string> }) {
        return (row: {[name:string]: any}) => {
          const repl: {[name:string]: any} = {};
          for (let [type, k, x] of data) {
            if (type === "relation") {
                repl["_" + k] = names[x].get(row[k]) || "Unbekannt";
            } else if (type === "relationList") {
                repl["_" + k] = row[k].map((f: number) => names[x].get(f) || "Unbekannt").sort((a:string,b:string) => a.localeCompare(b)).join(", ");
            } else if (type === "enum") {
                repl["_" + k] = x[row[k]] || "Unbekannt";
            }
          }
          return { ...row, ...repl };
        };
      };
    };
    return {
      rowFormatterFactory: rowFormatterFactoryFactory(rowFormatterFactoryData),
      headCells,
      namedEntities,
    };
  }, [definition.fields]);

  const names = useQueries<
    UseQueryOptions<{ id: number; name: string }[]>[],
    { [type: string]: Map<number, string> }
  >({
    queries: namedEntities.map((ee) => {
      return {
        queryKey: ["get" + ee[0].toUpperCase() + ee.slice(1) + "s"],
        queryFn: ApiService.getEntities(ee),
      };
    }),
    combine: (results) =>
      results
        .map((r, i) => [r, namedEntities[i]])
        .filter(([r, _typeName]) => r.isSuccess)
        .reduce(
          (
            carry: { [type: string]: Map<number, string> },
            [xquery, typeName]
          ) => {
            carry[typeName] = new Map<number, string>();
            for (let data of xquery.data) {
              carry[typeName].set(data.id, data.name);
            }
            return carry;
          },
          {}
        ),
  });

  const rowFormatter = useMemo(
    () => rowFormatterFactory(names),
    [rowFormatterFactory, names]
  );

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {definition.createable ? (
        <TopBar>
          <Button
            onClick={() => navigate("/settings/" + type + "/0")}
            variant="text"
            startIcon={<Add />}
          >
            Hinzufügen
          </Button>
        </TopBar>
      ) : null}
      <MainContainer>
        {query.isLoading ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : query.isError ? (
          <ErrorIndicator />
        ) : query.data && query.data.length > 0 ? (
          <EnhancedTable
            initialSort={definition.sortBy}
            rowFormatter={rowFormatter}
            rows={query.data}
            filter
            headCells={headCells}
            type={type}
            buttons={(row) => <ButtonGroup>
                <IconButton
                  aria-label="edit"
                  onClick={() =>
                    navigate(
                          "/settings/" + type + "/" + row.id
                        )
                  }
                >
                  <Edit />
                </IconButton>
                {definition.deleteable ? <DeleteButton type={type} id={row.id} /> : null}
              </ButtonGroup>}
          />
        ) : (
          <EmptyListIndicator name={definition.title} />
        )}
      </MainContainer>
      <ErrorDialog forceOpen={query.isError && query.error.status === 401} />
    </Box>
  );
}
