import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import { Delete, ExpandMore } from "@mui/icons-material";
import { SubcontractorOrder as SO } from "../Project";



export default function SubcontractorOrder({
  deleteOrder,
  so,
}: {
  deleteOrder: () => void;
  so: SO;
}) {
   return (
    <Accordion disableGutters>
      <AccordionSummary
        sx={{
          background: "#C5372C",
          color: "white",
          borderBottom: "1px solid rgba(255,255,255,0.5)",
        }}
        expandIcon={<ExpandMore sx={{ color: "white" }} />}
      >
        
        <Typography sx={{ flexShrink: 0, flex: 1 }}>
          {so.subcontractor.name}
        </Typography>
      
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)" }}
      >
        
        <Box>{so.comment}</Box>
        <Button variant="outlined" color="error" onClick={deleteOrder} startIcon={<Delete />}>
                Löschen
              </Button>
      </AccordionDetails>
    </Accordion>
  );
}
