import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Error, Loop, SaveTwoTone, SyncProblem } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { merge, uniqueId } from "lodash";
import { CalendarBackdrop } from "./elements/PortalDateRangePicker";
import ArticlePage from "./pages/ArticlePage";
import BaseDataPage from "./pages/BaseDataPage";
import PlanningPage from "./pages/PlanningPage";
import { useLocation, useParams } from "react-router-dom";
import StoffstromPage from "./pages/StoffstromPage";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import FilePage from "./pages/FilePage";
import ResourceTab from "./editors/ResourceTab";
import ApiService from "@/api/ApiService";

export interface SupplierOrder {
  id: number;
  number: string;
  validFrom: string;
  validUntil: string;
  supplier: Supplier;
  positions: OrderPosition[];
  deleted?: boolean;
  externalId: string;
}

export interface OrderPosition {
  id: number;
  article: Article;
  deliveryType: DeliveryType;
  price: number | null;
  amount: number | null;
}

export interface Article {
  id: number;
  number: string;
  name: string;
  plantName: string;
  supplierName: string;
}

export interface DeliveryType {
  id: number;
  name: string;
}

export interface Supplier {
  id: number;
  number: string;
  name: string;
  contact: string;
  phone: string;
  email: string;
}

export interface Subcontractor {
  name: string;
  id: number;
}

export interface Client {
  name: string;
  id: number;
}

export interface SubcontractorOrder {
  subcontractor: Subcontractor;
  comment: string;
  id: number;
  deleted?: boolean;
}

export interface ProjectFile {
  id: number;
  deleted: boolean;
  description: string;
  filename: string;
  createdAt: string;
}

export interface ProjectState {
  id?: number;
  name: string;
  region: number;
  number: string;
  client: number | null;
  position: SitePosition;
  files: ProjectFile[];
  resourceOrders: (ResourceOrder & WithExceptions & WithPhaseExceptions)[];
  plannedStart: string | null;
  plannedEnd: string | null;
  manager: number | null;
  supplierOrders: SupplierOrder[];
  subcontractorOrders: SubcontractorOrder[];
  phases: ProjectPhase[];
  comment: string;
  stoffstrom: StoffstromOrder[];
}

export interface StoffstromOrder {
  attachments: { filename: string; originalName: string }[];
  id: number;
  deleted?: boolean;
  type: "OFFER" | "REQUEST";
  comment: string;
  category: string;
  amount: string;
  date: string;
}

export interface MaterialOrder {
  id: number;
  deleted?: boolean;
  comment: string;
  article: Article | null;
  position: OrderPosition | null;
  amount: number;
  takt: number;
  transportInformation: TruckInfo[] | null;
  firstUnload: string | null;
}

export interface TruckInfo {
  type: "XX" | "SA" | "3A" | "4A" | "3A4A" | "HZ";
  amount: number | null;
  thermo: boolean;
  siteWork: boolean;
  bordmatik: boolean;
}

export const initialMaterialOrder: () => MaterialOrder = () => ({
  id: 0,
  deleted: false,
  amount: 100,
  comment: "",
  article: null,
  position: null,
  takt: 0,
  firstUnload: "07:00",

  transportInformation: [
    {
      type: "XX",
      amount: null,
      thermo: false,
      bordmatik: false,
      siteWork: false,
    },
  ],
});

export interface ResourceOrder {
  id: number;
  deleted?: boolean;
  amount: number;
  tag: number | null;
  autoBooking: boolean;
  comment: string;
  type: "CREW" | "RESOURCE" | "HUMAN";
  references: (number | null)[];
}

export const initialResourceOrder: () => ResourceOrder = () => ({
  id: 0,
  deleted: false,
  amount: 1,
  tag: null,
  comment: "",
  autoBooking: false,
  type: "CREW",
  references: [null],
});

export interface ProjectPhase {
  id?: number;
  name: string;
  comment: string;
  uuid: string;
  trade: number | null;
  position: SitePosition | null;
  plannedStart: string | null;
  plannedEnd: string | null;
  subcontractor: number | null;
  deleted?: boolean;
  jobs: { [date: string]: ProjectJob };
  resourceOrders: (ResourceOrder & WithExceptions)[];
}

export interface WithExceptions {
  exceptions: string[];
}

export interface WithPhaseExceptions {
  phaseExceptions: string[];
}

export interface SitePosition {
  site: { lat: number; lng: number };
  yard: { lat: number; lng: number };
  useYard: boolean;
  onlySolo: boolean;
  comment: string;
}

export const initialPosition = {
  site: { lat: 0, lng: 0 },
  yard: { lat: 0, lng: 0 },
  useYard: false,
  comment: "",
  onlySolo: false,
};

const initialState: (region: number) => ProjectState = (region) => {
  return {
    name: "",
    comment: "",
    number: "",
    position: merge({}, initialPosition),
    plannedStart: null,
    plannedEnd: null,
    region,
    manager: null,
    files: [],
    client: null,
    supplierOrders: [],
    resourceOrders: [],
    subcontractorOrders: [],
    stoffstrom: [],
    phases: [],
  };
};

export interface ProjectJob {
  date: string;
  comment: string;
  deleted?: boolean;
  id: number;
  status: "PLANNING" | "RAW" | "FINE";
  position: SitePosition | null;
  materialOrders: MaterialOrder[];
  resourceOrders: ResourceOrder[];
  uuid: string;
  startTime: string | null;
}

export const initialJob: (date: string) => ProjectJob = (date) => ({
  date,
  comment: "",
  id: 0,
  uuid: self.crypto.randomUUID(),
  deleted: false,
  status: "PLANNING",
  position: null,
  materialOrders: [],
  resourceOrders: [],
  startTime: "07:00",
});

export default function Project() {
  const { id: rawId } = useParams();
  const location = useLocation();
  const [initialSelection, setInitialSelection] = useState({
    date: null,
    phase: 0,
  });
  const [wasChanged, setChanged] = useState(false);
  const initialPage = !!location.state?.phaseId ? location.state : null;
  const id = parseInt(rawId ?? "0");
  const region = usePersistantStore((s) => s.region);
  const [project, setState] = useState<ProjectState>(initialState(region));
  const [loadingState, setLoadingState] = useState<
    "loading" | "error" | "ready" | "initialLoading" | "initialError"
  >((() => { const xx = project.id === id ? "loading" : "initialLoading"; console.log("REDO DERO",project.id, id, project.id === id, xx); return xx;})());



  const loadData = useCallback(async () => {
    console.log("start of ",loadingState);
    setLoadingState(loadingState === "initialLoading" ? "initialLoading" : "loading");
    try {
      const data = await ApiService.getEntity("project", id)();

      if (Array.isArray(data.position)) {
        data.position = { ...initialPosition };
      }

      data.supplierOrders = await ApiService.getEntitiesWithFilter(
        "supplierOrder",
        { project: ["project", id] }
      )();
      data.subcontractorOrders = await ApiService.getEntitiesWithFilter(
        "subcontractorOrder",
        { project: ["project", id] }
      )();
      data.phases = await ApiService.getEntitiesWithFilter("projectPhase", {
        project: ["project", id],
      })();
      data.stoffstrom = await ApiService.getEntitiesWithFilter(
        "stoffstromEntry",
        {
          project: ["project", id],
        }
      )();
      if (!!initialPage) {
        for (let index = 0; index < data.phases.length; index++) {
          if (data.phases[index].id === initialPage.phaseId) {
            setInitialSelection({
              date: initialPage.date,
              phase: index,
            });
            break;
          }
        }
      }
      setState(data);
      setChanged(false);
      setLoadingState("ready");
    } catch (e) {
      console.log(e);
      setLoadingState(loadingState === "initialLoading" ? "initialError" :"error");
    }
  }, []);

  const autoBookMutation = useMutation<any, AxiosResponse, number>({
    mutationFn: ApiService.autoBook()
  });

  const saveMutation = useMutation<ProjectState, AxiosResponse, ProjectState>({
    mutationFn:
      id > 0
        ? ApiService.editEntity("project", id)
        : ApiService.createEntity("project"),
    onSuccess: (answer) => {
      autoBookMutation.mutate(answer.id ?? 0);
      console.log("End of mu",loadingState);
      loadData();
    },
  });

  console.log(loadingState);

  

  useEffect(() => {
    if (id === 0) {
      setState(initialState(region));
      setChanged(false);
      setLoadingState("ready");
      return;
    }
    loadData();
  }, [id]);

  
  const [currentPage, setCurrentPage] = useState<
    "PROJECT" | "ARTICLES" | "PLANNING" | "STOFFSTROM" | "UPLOAD" | "RESOURCES"
  >(!!initialPage ? "PLANNING" : "PROJECT");

  useEffect(
    () =>
      setPageTitle(
        [project.number, project.name].filter((x) => x.length > 0).join(": ") ||
          "Neues Projekt"
      ),
    [project.number, project.name]
  );
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const setField = (changes: Partial<ProjectState>) => {
    const newState = { ...project, ...changes };
    setChanged(true);
    setState(newState);
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      {(loadingState === "initialError") ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Es gab einen Fehler. Bitte versuche es erneut oder melde dich neu an.
        </Box>
      ) : loadingState === "initialLoading" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              borderRight: "1px solid #aeaeae",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                zIndex: 20,
                py: "5px",
                borderBottom: "1px solid #999",
                boxShadow: "0px 3px 3px rgba(0,0,0,0.2)",
              }}
            >

              <Button
                color={saveMutation.isError || loadingState === "error" || wasChanged ? "error" : "primary"}
                disabled={saveMutation.isPending}
                onClick={() => saveMutation.mutate(project)}
                startIcon={saveMutation.isPending || loadingState === "loading" ? <Loop /> : saveMutation.isError || loadingState === "error" ? <SyncProblem /> : <SaveTwoTone />}
              >
                Speichern
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ToggleButtonGroup
                sx={{
                  mb: "20px",
                  background: "white",
                  ".MuiToggleButtonGroup-firstButton": { borderTop: "none" },
                  ".MuiToggleButtonGroup-groupedVertical": {
                    borderRadius: 0,
                    borderLeft: "none",
                    borderRight: "none",
                  },
                }}
                orientation="vertical"
                exclusive
                fullWidth
                value={currentPage}
                onChange={(_, val) => val && setCurrentPage(val)}
              >
                <ToggleButton value="PROJECT">Stammdaten</ToggleButton>
                <ToggleButton value="ARTICLES">Einkauf</ToggleButton>
                <ToggleButton value="PLANNING">Planung</ToggleButton>
                <ToggleButton value="RESOURCES">Personal & Geräte</ToggleButton>
                <ToggleButton value="STOFFSTROM">Stoffstrom</ToggleButton>
                <ToggleButton value="UPLOAD">Dateien</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              bgcolor: "background.paper",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                background: "#eee",
              }}
            >
              {currentPage === "PLANNING" ? (
                <PlanningPage
                saveProject={() => saveMutation.mutate(project)}
                  initialSelection={initialSelection}
                  project={project}
                  setField={setField}
                />
              ) : currentPage === "RESOURCES" ? (
                <ResourceTab
                  region={project.region}
                  projectState={project.resourceOrders}
                  setProjectState={(resourceOrders) =>
                    setField({ resourceOrders })
                  }
                  phaseState={[]}
                  setPhaseState={() => {}}
                  jobState={[]}
                  setJobState={() => {}}
                  jobUuid={null}
                  phaseUuid={null}
                />
              ) : currentPage === "ARTICLES" ? (
                <ArticlePage project={project} setField={setField} />
              ) : currentPage === "STOFFSTROM" ? (
                <StoffstromPage project={project} setField={setField} />
              ) : currentPage === "UPLOAD" ? (
                <FilePage project={project} setField={setField} />
              ) : (
                <BaseDataPage project={project} setField={setField} />
              )}
            </Box>
          </Box>
          <CalendarBackdrop />
        </Fragment>
      )}
    </Box>
  );
}
