import { Marker } from "react-leaflet";
import { useRef, useMemo } from "react";
import L from "leaflet";
import shadow from "@/shared/assets/shadow.png";
import whiteA from "@/shared/assets/whiteA.png";
import whiteB from "@/shared/assets/whiteB.png";
import blackA from "@/shared/assets/blackA.png";
import blackB from "@/shared/assets/blackB.png";
import blueA from "@/shared/assets/blueA.png";
import blueB from "@/shared/assets/blueB.png";
import waypoint from "@/shared/assets/waypoint.png";

const icon = (iconUrl: string, height: number) =>
  new L.Icon({
    iconUrl,
    zIndexOffset: 5000,
    shadowUrl: shadow,
    iconAnchor: new L.Point(0.5 * 0.756 * height, height),
    popupAnchor: new L.Point(0, -1 * height),
    shadowSize: new L.Point(0.9 * height, 0.6 * height),
    shadowAnchor: new L.Point(0.14 * height, 0.5 * height),
    iconSize: new L.Point(0.756 * height, height),
  });

export const whiteIconA = icon(whiteA, 50);
const whiteIconB = icon(whiteB, 50);
const blackIconA = icon(blackA, 50);
export const blackIconB = icon(blackB, 50);
const blueIconA = icon(blueA, 60);
const blueIconB = icon(blueB, 60);

export const waypointIcon = ((width: number) =>
  new L.Icon({
    iconUrl: waypoint,
    iconAnchor: new L.Point(0.5 * width, 0.5 * width),
    iconSize: new L.Point(width, width),
  }))(15);

export function DraggableMarker({position, setPosition, draggable, noDragEffect = false, color, letter, onClick}) {
  const markerRef = useRef(null)
  const eventHandlers = {
      click() {
        !!onClick && onClick();
      },
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    };


  return (
    <Marker
      draggable={draggable}
      icon={draggable && !noDragEffect ? (letter === "A" ? blueIconA : blueIconB) : color === "black" ? (letter === "A" ? blackIconA : blackIconB) : (letter === "A" ? whiteIconA : whiteIconB)}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef} />
  )
}
