import { Box } from "@mui/material";

export default function LabelWrapper({ label, children }) {
  return (
    <Box
    className="labelwrapper"
      sx={{
        
        borderBottom: "1px solid #aaa",
        px: "15px",
        pb: "15px",
        pt: "12px",
        ":nth-child(even)": { background: "#f8f8f8" },
        ".MuiInputBase-input, .react-time-picker__wrapper":{
            background: "white"
        },
        ".MuiInputBase-root.MuiOutlinedInput-root": {
          background: "white"
        }
      }}
    >
      <Box sx={{textTransform: "uppercase", display: "flex", justifyContent: "space-between", fontSize: "14px", color: "#333", mb: "5px"}}>{label}</Box>
      {children}
    </Box>
  );
}
