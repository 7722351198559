import { Fragment, useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import BaseApiService from "../api/BaseApiService";
import { useQuery } from "@tanstack/react-query";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ErrorDialog from "../dialogs/ErrorDialog";
import ErrorIndicator from "../dialogs/ErrorIndicator";
import { DeleteTwoTone, AddCircleOutlineTwoTone } from "@mui/icons-material";

interface BasicEntity {
  id: number;
  name: string;
}

export default function ManyToManySelect<T extends BasicEntity>({
  entity,
  currentIds,
  setIds,
  label,
  showWithoutChoice = false,
  atLeastOne = true,
  sx = {}
}: {
  entity: string;
  currentIds: number[];
  setIds: (newIds: number[]) => any;
  label: string;
  showWithoutChoice?: boolean;
  atLeastOne?: boolean;
  sx?: any;
}) {
  const query = useQuery<T[], AxiosResponse>({
    queryKey: ["get" + entity[0].toUpperCase() + entity.slice(1) + "s"],
    queryFn: BaseApiService.getEntities(entity),
  });

  const [names, setNames] = useState(new Map<number, string>());

  useEffect(() => {
    if (!query.isSuccess) return;
    const names = new Map<number, string>();
    for (let o of query.data) {
      names.set(o.id, o.name);
    }
    if (names.size === 1 && atLeastOne) {
      setIds([names.keys().next().value]);
    } else {
      const ids = Array.from(names.keys());
      setIds(currentIds.filter(id => ids.includes(id)));
    }
    setNames(names);
  }, [query.data]);


  const addLine = () => setIds([...currentIds, 0]);

  const changeLine = (line: number, value: number) => {
    const newIds = currentIds.slice();
    newIds[line] = value;
    setIds(newIds);
  };

  const deleteLine = (line: number) => {
    const newIds = currentIds.slice();
    newIds.splice(line,1);
    setIds(newIds);
  };

  return !showWithoutChoice && atLeastOne && currentIds.length === 1 && currentIds[0] !== 0 && names.size === 1  ? null : (
    <Paper
      variant="outlined"
      sx={{
        mt: "12px",
        position: "relative",
        mb: "4px",
        borderColor: "rgba(0,0,0,0.25)",
        ...sx
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          position: "absolute",
          display: "inline-block",
          lineHeight: 1,
          left: "8px",
          top: "-10px",
          px: "5px",
        }}
      >
        <Typography sx={{ color: "rgba(0,0,0,0.6)" }} variant="caption">
          {label}
        </Typography>
      </Box>
      <Box sx={{ padding: 1.5 }}>
        {query.isLoading ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : query.isError ? (
          <Fragment>
            <ErrorIndicator />
            <ErrorDialog
              forceOpen={query.isError && query.error.status === 401}
            />
          </Fragment>
        ) : (
          <Fragment>
            {currentIds.map((id, i) => (
              <Box key={i} sx={{ display: "flex", my: "10px" }}>
                <Autocomplete
                  size="small"
                  value={!!id ? id : null}
                  onChange={(_event: any, newValue: number | null) => {
                    changeLine(i, !!newValue ? newValue : 0);
                  }}
                  fullWidth
                  
                  options={query.data
                    .sort((a: T, b: T) => -b.name.localeCompare(a.name))
                    .map((x: T) => x.id)}
                  groupBy={(id: number) =>
                    (names.get(id) ?? "").substring(0, 1).toUpperCase()
                  }
                  getOptionLabel={(id: number) => names.get(id) ?? ""}
                  renderInput={(params: any) => <TextField placeholder="Bitte wählen..." {...params} />}
                />
                <IconButton onClick={() => deleteLine(i)}>
                  <DeleteTwoTone />
                </IconButton>
              </Box>
            ))}
            <Button
              sx={{ mt: "4px" }}
              onClick={addLine}
              variant="contained"
              startIcon={<AddCircleOutlineTwoTone />}
            >
              Hinzufügen
            </Button>
          </Fragment>
        )}
      </Box>
    </Paper>
  );
}
