import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";


export default function Countdown({to, ended}: {to: dayjs.Dayjs}){
    const [timeLeft, setTimeLeft] = useState(to.fromNow());

  useEffect(() => {
    // exit early when we reach 0
    if (to <= dayjs()){ ended(); return;}

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(to.fromNow());
    }, 10000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  
    return <Box sx={{textAlign: "center", color: "#555"}}>Bestellfrist endet {timeLeft}</Box>
}