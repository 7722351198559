import {
  Box,
  Paper,
} from "@mui/material";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { useEffect } from "react";
import ProjectList from "./ProjectList";
import ProjectMap from "./ProjectMap";



export default function TestList({}) {
  const region = usePersistantStore(store=>store.region);

  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  useEffect(() => setPageTitle("Start"), []);



  return (
    <Box
      sx={{
        flex: 1,
        display: "grid",
        overflow: "hidden",
        background: "#eee",
        padding: 2,
        gap: 2,
        gridTemplateColumns: "repeat( auto-fit, minmax(350px, 2fr) )",
        gridTemplateRows: "masonry"
      }}
    >
       
        <ProjectList filter={{manager: "%USER"}} title="Meine Projekte" />
        <ProjectList filter={!!region ? {region: ["region",region]} : {}} title="Alle Projekte" />
        <ProjectMap region={region} />
    </Box>
  );
}
