import { Call, ChatBubble, Check, Delete, DoneAll, Edit, People, PeopleAlt, Signpost, Tag } from "@mui/icons-material";
import { useNavigate, useNavigation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const Information = ({ data, did }) => {
  return (
    <Fragment>
      <div
        className="vc-res-status"
        style={{
          background: !data.orderSentAt ? "#99D4FF" : "#A6FF99",
        }}
      />
      <div className="infocomp">
      <div className="name">{data.phase.project.name+" ("+data.phase.project.number+")"}</div>
      {!!data.resourceOrder ? (
        <Fragment>
          {!!data.orderSentBy ? (
            <div className="info">
              <Call fontSize="inherit" /><div className="txt">{data.orderSentBy.firstName + " " + data.orderSentBy.name}</div>
            </div>
          ) : null}
          {data.resourceOrder.comment.length > 0 ? (
            <div className="info">
              <ChatBubble fontSize="inherit" /><div className="txt">{data.resourceOrder.comment}</div>
            </div>
          ) : null}
        </Fragment>
      ) : (did in data.crews) && Object.keys(data.crews).length === 1 ? null : (
        <Fragment>
          <div className="info"><PeopleAlt fontSize="inherit" /><div className="txt">{Object.values(data.crews).join("; ")}</div></div>
        </Fragment>
      )}
      </div>
    </Fragment>
  );
};

export default function ResInner({
  elements,
  did,
  i,
  changeMutation,
  deleteMutation,
}) {
  const navigate = useNavigate();
  const dates = Object.keys(elements).sort();
  const date = dates[0];
  const element = elements[date][i].element;

  return (
    <Fragment>
      <Information data={element} did={did} />
      {!!changeMutation ? (
        <div className="resOrderEdit">
          {!!element.resourceBooking ? (
            <div
              className="resOrderButton red"
              onClick={() => deleteMutation.mutate(element.resourceBooking.id)}
            >
              <Delete fontSize="inherit" />
            </div>
          ) : null}
          {did !== 0 && !!element.resourceOrder ? (
            <div
              className="resOrderButton green"
              onClick={() =>
                changeMutation.mutate({
                  move: null,
                  orderToBooking: {
                    order: element.resourceOrder.id,
                    jobId: element.id,
                    target: did,
                  },
                  bookingMove: null,
                })
              }
            >
              <Check fontSize="inherit" />
            </div>
          ) : (
            <div />
          )}
          {did !== 0 &&
          !!element.resourceOrder &&
          !element.resourceOrder.singleDayOrder ? (
            <div
              onClick={() =>
                changeMutation.mutate({
                  move: null,
                  orderToBooking: {
                    order: element.resourceOrder.id,
                    startDate: date,
                    jobId: null,
                    target: did,
                  },
                  bookingMove: null,
                })
              }
              className="resOrderButton green"
            >
              <DoneAll fontSize="inherit" />
            </div>
          ) : null}
          <div className="resOrderButton">
            <Edit
              onClick={() =>
                navigate("/project/" + element.phase.project.id, {
                  state: { phaseId: element.phase.id, date },
                })
              }
              fontSize="inherit"
            />
          </div>
        </div>
      ) : (
        false
      )}
    </Fragment>
  );
}
