import * as React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";

const MyNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ""}`
    }
  >
    {props.children}
  </NavLink>
));



export default function CustomListItem(props: {to: string, end?: boolean, open: boolean, Icon: React.ComponentType, text: string}) {
  return (
    <ListItem
      component={MyNavLink}
      to={props.to}
      activeClassName="active"
      end={props.end}
      className="customListItem"
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          title={props.text}
          sx={{
            minWidth: 0,
            color: "rgba(255, 255, 255, 0.9)",
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          <props.Icon />
        </ListItemIcon>
        <ListItemText primary={props.text} sx={{ opacity: props.open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}
