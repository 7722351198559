import { Box, CircularProgress, Paper } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";

export default function MultiSelector({
  onChange,
  value,
  query,
}: {
  query: UseQueryResult;
  value: (number | null)[];
  onChange: (references: (number | null)[]) => void;
}) {

  const onClick = (id) => {
    if (value.includes(id)){
        return onChange(value.map(x => x === id ? null : x));
    } else {
        let index = -1;
        for (let i = 0; i < value.length; i++) {
            if (!!value[i]) continue;
            index = i;
            break;
        }
        if (index >= 0){
            return onChange(value.map((x, i) => i === index ? id : x));
        } else {
            return onChange(value.slice(1).concat([id]));
        }
    }
  };


  return query.isPending ? (
    query.isFetching ? <CircularProgress /> : "Bitte wählen Sie eine Kategorie"
  ) : query.isError ? (
    "Fehler"
  ) : query.data.length === 0 ? (
    "Nichts gefunden"
  ) : (
    <Paper
      variant="outlined"
      sx={{ display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}
    >
      {query.data.map((x) => (
        <Box
          sx={{
            border: "1px solid #aaa",
            p: "2px",
            lineHeight: 1,
            fontSize: "14px",
            flex: 1,
            cursor: "pointer",
            whiteSpace: "nowrap",
            borderRadius: "3px",
            background: value.includes(x.id) ? "#002169" : "#eee",
            color: value.includes(x.id) ? "white" : "#222",
            "&:hover": { background: value.includes(x.id) ? "#000" : "#ddd", },
          }}
          onClick={() => onClick(x.id)}
        >
          {x.name}{!!x.number ? (" ("+x.number+")") : ""}
        </Box>
      ))}
    </Paper>
  );
}
