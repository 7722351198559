import { Box, Paper, TextField } from "@mui/material";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { User } from "@/types/types";
import dayjs from "dayjs";
import LabelWrapper from "../../root/LabelWrapper";
import PortalDateRangePicker from "../elements/PortalDateRangePicker";
import { PositionEditor } from "../editors/PositionEditor";
import { Client, initialPosition, ProjectState, SitePosition } from "../Project";
import { usePersistantStore } from "@/stores/global";

export default function BaseDataPage({project, setField}: {
  project: ProjectState;
  setField: (changes: Partial<ProjectState>) => void;
}) {
  
  const region = usePersistantStore((store) => store.region);
  return <Paper
    sx={{
      p: 0,
      flex: 1,
      display: "flex",
      overflow: "hidden",
      alignItems: "stretch",
    }}
  >
    <Box sx={{ minWidth: "400px", flex: 1, overflow: "auto" }}>
     
      <LabelWrapper label="Name">
        <TextField
          size="small"
          fullWidth
          value={project.name}
          placeholder="Name des Projektes"
          onChange={(e) =>
            setField({
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
      </LabelWrapper>
      

      <LabelWrapper label="Kostenstelle">
        <TextField
          size="small"
          fullWidth
          value={project.number}
          placeholder="Kostenstelle"
          onChange={(e) =>
            setField({
              number: (e.target as HTMLInputElement).value,
            })
          }
        />
      </LabelWrapper>
      
      <LabelWrapper label="Geplante Bauzeit">
        <PortalDateRangePicker
          small
          onChange={([startDate, endDate]) =>
            setField({
              plannedStart: !!startDate
                ? dayjs(startDate).format("YYYY-MM-DD")
                : null,
              plannedEnd: !!endDate
                ? dayjs(endDate).format("YYYY-MM-DD")
                : null,
            })
          }
          value={[
            !!project.plannedStart ? dayjs(project.plannedStart).toDate() : null,
            !!project.plannedEnd ? dayjs(project.plannedEnd).toDate() : null,
          ]}
        />
      </LabelWrapper>
      <LabelWrapper label="Bauleiter">
        <ManyToOneSelect<User & {id: number}>
          size="small"
          disabled
          showWithoutChoice
          autoSelect={false}
          remoteFilter={{ region: ["region", project.region] , "contactType":"MANAGER"}}
          entity={"user"}
          displayFunction={o => !!o ? o.name+", "+o.firstName : "(unbekannt)"}
          currentId={project.manager ?? 0}
          setId={id => setField({
            manager: id > 0 ? id : null,
          })}
          placeholder={"Bauleiter"}
        />
      </LabelWrapper>
      <LabelWrapper label="Auftraggeber">
        <ManyToOneSelect<Client & {id: number}>
          disabled
          size="small"
          entity={"client"}
          showWithoutChoice
          remoteFilter={{ region: ["region", project.region] }}
          autoSelect={false}
          displayFunction={o => !!o ? o.name : "(unbekannt)"}
          currentId={project.client ?? 0}
          setId={id => setField({
            client: id > 0 ? id : null,
          })}
          placeholder={"Auftraggeber"}
        />
      </LabelWrapper>
      <LabelWrapper label="Kommentar">
        <TextField
          size="small"
          fullWidth
          value={project.comment}
          multiline
          placeholder="Kommentar"
          onChange={(e) =>
            setField({
              comment: (e.target as HTMLInputElement).value,
            })
          }
        />
      </LabelWrapper>
    </Box>
    <Box
      id="mapContainer"
      sx={{
        flex: 10,
        borderLeft: "1px solid #aaa",
        position: "relative",
        
      }}
    >
      <PositionEditor
        position={project.position || initialPosition}
        onChange={(changes: Partial<SitePosition>) =>
          setField({ position: { ...project.position || initialPosition, ...changes } })
        }
      />
    </Box>
  </Paper>;}