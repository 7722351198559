import { useGlobalStore } from "@/stores/global";
import { Backdrop, Portal } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Fragment, useEffect, useState } from "react";

export default function PortalDateRangePicker({ onChange, value, small=false }) {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);

  const setBackdropVisible = useGlobalStore((state) => state.setBackdropVisible);

  return rendered ? (
    <DateRangePicker
      showLeadingZeros
      onCalendarOpen={() => setBackdropVisible(true)}
      onCalendarClose={() => setBackdropVisible(false)}
      calendarProps={{
        className: "portalCalendar",
        showDoubleView: true,
        showWeekNumbers: true,
        showFixedNumberOfWeeks: true,
        next2Label: null,
        prev2Label: null
      }}
      className={small ? "small":""}
      portalContainer={document.getElementById("calendarBackdrop")}
      clearIcon={null}
      onChange={onChange}
      value={value}
    />
  ) : null;
}

export const CalendarBackdrop = () => {
  const open = useGlobalStore((state) => state.backdropVisible);
  const setBackdropVisible = useGlobalStore(
    (state) => state.setBackdropVisible
  );

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 10000 }}
      open={open}
      onClick={() => setBackdropVisible(false)}
    >
      <div id="calendarBackdrop" className="react-calendar" />
    </Backdrop>
  );
};
