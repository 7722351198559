import { Fragment, useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import BaseApiService from "../api/BaseApiService";
import { useQuery } from "@tanstack/react-query";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import ErrorDialog from "../dialogs/ErrorDialog";
import ErrorIndicator from "../dialogs/ErrorIndicator";
import {
  DeleteTwoTone,
  AddCircleOutlineTwoTone,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  AddBoxRounded,
  CheckBoxOutlineBlank,
  CheckBox,
  Search,
} from "@mui/icons-material";
import React from "react";

interface BasicEntity {
  id: number;
  name: string;
}

export default function ListBasedManyToManySelect<T extends BasicEntity>({
  entity,
  currentIds,
  setIds,
  remoteFilter,
  disabled = false,
  groupByFunction,
  displayFunction = (o) => (!!o ? o.name : ""),
}: {
  groupByFunction: ((object: any) => string) | undefined;
  entity: string;
  displayFunction?: (x: T | undefined) => string;
  currentIds: number[];
  remoteFilter?: { [field: string]: string | [string, any] };
  setIds: (newIds: number[]) => any;
  disabled: boolean;
}) {
  const query = useQuery<T[], AxiosResponse>({
    queryKey: !!remoteFilter
      ? [
          "get" + entity[0].toUpperCase() + entity.slice(1) + "s",
          JSON.stringify(remoteFilter),
        ]
      : ["get" + entity[0].toUpperCase() + entity.slice(1) + "s"],
    queryFn: !!remoteFilter
      ? BaseApiService.getEntitiesWithFilter(entity, remoteFilter)
      : BaseApiService.getEntities(entity),
  });

  const [names, setNames] = useState(new Map<number, T>());
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!query.isSuccess) return;
    const names = new Map<number, T>();
    for (let o of query.data) {
      names.set(o.id, o);
    }
    setIds(currentIds.filter((id) => names.has(id)));
    setNames(names);
  }, [query.data]);

  const addSelection = (id) =>
    setIds(
      currentIds.includes(id) ? currentIds : currentIds.slice().concat([id])
    );
  const removeSelection = (id) =>
    setIds(currentIds.slice().filter((x) => id !== x));

  const search = useMemo(() => {
    if (!filter.length) return () => true;

    const searchTerms = filter
      .split(" ")
      .map((x) => x.toLocaleLowerCase().trim().replace(/_/g, " "))
      .filter((x) => !!x.length);
    if (!filter.length) return () => true;
    return (row) => {
      for (let term of searchTerms) {
        let found = false;
        const headCells = [displayFunction(row)];
        if (!!groupByFunction) headCells.push(groupByFunction(row));
        for (let headCell of headCells) {
          if (headCell.toLocaleLowerCase().includes(term)) {
            found = true;
            break;
          }
        }
        if (!found) return false;
      }
      return true;
    };
  }, [filter, displayFunction, groupByFunction]);

  return !query.isSuccess ? (
    <Skeleton variant="rectangular" sx={{ fontSize: "2rem" }} />
  ) : (
    <Box sx={{ display: "flex", overflow: "hidden", width: "100%", columnGap: "5px" }}>
      <Box
        className="lbm_part"
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box className="lbm_title"><Box className="lbm_title_inner">Alle</Box><Box className="lbm_search">
          <TextField
            size="small"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            value={filter}
            placeholder={"Filtern"}
            onChange={(e) => setFilter((e.target as HTMLInputElement).value)}
          />
        </Box></Box>
        
        <Box
          className="lbm_list"
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {query.data
            .filter((x) => !currentIds.includes(x.id) && search(x))
            .sort((a, b) => {
              let g = 0;
              if (!!groupByFunction) {
                g = groupByFunction(a).localeCompare(groupByFunction(b));
              }
              if (g === 0) {
                return displayFunction(a).localeCompare(displayFunction(b));
              } else return g;
            })
            .map((x, i, a) => (
              <Fragment key={x.id}>
                {!!groupByFunction &&
                (i === 0 ||
                  groupByFunction(x) !== groupByFunction(a[i - 1])) ? (
                  <Box
                    className="lbm_group"
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {groupByFunction(x)}
                  </Box>
                ) : null}
                <Box
                  className="lbm_element"
                  sx={{
                    display: "flex",
                    columnGap: "5px",
                    alignItems: "center",
                  }}
                >
                  {!!disabled ? null : (
                    <IconButton size="small" onClick={() => addSelection(x.id)}>
                      <CheckBoxOutlineBlank />
                    </IconButton>
                  )}
                  <Box sx={{ p: 1, flex: 1 }}>{displayFunction(x)}</Box>
                </Box>
              </Fragment>
            ))}
        </Box>
      </Box>
      <Box
        className="lbm_part"
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box className="lbm_title"><Box className="lbm_title_inner">Auswahl</Box></Box>
        <Box className="lbm_list"
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {currentIds
            .map((x) => names.get(x))
            .filter((x) => !!x)
            .sort((a, b) => {
              let g = 0;
              if (!!groupByFunction) {
                g = groupByFunction(a).localeCompare(groupByFunction(b));
              }
              if (g === 0) {
                return displayFunction(a).localeCompare(displayFunction(b));
              } else return g;
            })
            .map((x, i, a) => (
              <Fragment key={x.id}>
                {!!groupByFunction &&
                (i === 0 ||
                  groupByFunction(x) !== groupByFunction(a[i - 1])) ? (
                  <Box
                  className="lbm_group"
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    {groupByFunction(x)}
                  </Box>
                ) : null}
                <Box
                className="lbm_element"
                  sx={{
                    display: "flex",
                    columnGap: "10px",
                    alignItems: "center",
                  }}
                >
                  {!!disabled ? null : (
                    <IconButton
                      size="small"
                      onClick={() => removeSelection(x.id)}
                    >
                      <CheckBox />
                    </IconButton>
                  )}
                  <Box sx={{ p: 1, flex: 1 }}>{displayFunction(x)}</Box>
                </Box>
              </Fragment>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
