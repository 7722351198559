import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { usePersistantStore } from "@/stores/global";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AuthErrorDialog({
  forceOpen,
}: {
  forceOpen: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const setAuthenticated = usePersistantStore((state) => state.setAuthenticated);

  const logout = () => {
    setOpen(false);
    setAuthenticated(false);
  };

  React.useEffect(() => {
    setOpen(forceOpen || open)
  }, [forceOpen]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
    >
      <DialogTitle>Sitzung abgelaufen</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            Ihre Sitzung ist abgelaufen und Sie wurden automatisch abgemeldet. Bitte melden Sie sich neu an.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={logout}>Neu anmelden</Button>
      </DialogActions>
    </Dialog>
  );
}
