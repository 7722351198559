import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Alert, Box, IconButton, Paper } from "@mui/material";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import VerticalCalendar from "../shared/VerticalCalendar";
import SelectCalendarDialog from "./dialogs/SelectCalendarDialog";
import InnerCalendar from "./calendarElements/InnerCalendar";
import dayjs from "dayjs";
import { AddCircle } from "@mui/icons-material";
import AddFastResourceDialog from "./dialogs/AddFastResource";
import OpenRequests from "./OpenRequests";
import CalendarListView from "./listView/CalendarListView";
import CalendarSettings from "./dialogs/CalendarSettings";

export type PossibleCalendarTypes =
  | "PROJECTS"
  | "CREWS"
  | "STAFF"
  | "RESOURCES"
  | "MATERIAL"
  | "DISPO";

export default function MainCalendar() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const calendar = useRef();
  const type = usePersistantStore((state) => state.type);
  const setType = usePersistantStore((state) => state.setType);
  const reference = usePersistantStore((state) => state.reference);
  const setReference = usePersistantStore((state) => state.setReference);
  const [selectedDate, setSelectedDate] = useState(null);
  const settings = usePersistantStore((state) => state.settings);
  const setSettings = usePersistantStore((state) => state.setSettings);

  useEffect(() => setPageTitle("Kalender"), []);

  const createDayButtons = useCallback(
    (date) => (
      <IconButton onClick={() => setSelectedDate(date)}>
        <AddCircle />
      </IconButton>
    ),
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 1,
          background: "#eee",
        }}
      >
        {navigator.userAgent.toLowerCase().includes("firefox") ? (
          <Alert sx={{ mb: "10px" }} variant="filled" severity="warning">
            Der Kalender funktioniert im Firefox Browser leider nur
            eingeschränkt. Wir empfehlen deshalb Google Chrome oder Microsoft
            Edge.
          </Alert>
        ) : null}

        <Paper
          sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
        >
          {type === "DISPO" || type === "MATERIAL" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  background: "#edf0f9",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                  display: "flex",
                  columnGap: "5px",
                  boxShadow:
                    "inset 0px -4px 5px rgba(0,0,0,0.1)" /*borderBottom: "none"*/,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    ml: "5px",
                  }}
                >
                  <SelectCalendarDialog
                    type={type}
                    reference={reference}
                    onChange={(type, reference) => {
                      setType(type);
                      setReference(reference);
                    }}
                  />
                </Box>
              </Box>
              <CalendarListView type={type} reference={reference} />
            </Box>
          ) : (
            <VerticalCalendar
              ref={calendar}
              initialCalEnd={dayjs().endOf("week")}
              initialCalStart={dayjs().startOf("week")}
              dateButtons={
                ["CREWS", "STAFF", "RESOURCES"].includes(type)
                  ? createDayButtons
                  : null
              }
              buttons={
                <div className="calendarButtons"><SelectCalendarDialog
                  type={type}
                  reference={reference}
                  onChange={(type, reference) => {
                    setType(type);
                    setReference(reference);
                  }}
                /><CalendarSettings type={type} settings={settings} setSettings={setSettings} /><div /></div>
              }
            >
              {!!type ? (
                <InnerCalendar type={type} settings={settings} reference={reference} />
              ) : (
                <Fragment />
              )}
            </VerticalCalendar>
          )}
          {["CREWS", "STAFF", "RESOURCES"].includes(type) ? (
            <AddFastResourceDialog
              date={selectedDate}
              type={type}
              reference={reference}
              close={() => setSelectedDate(null)}
            />
          ) : null}
        </Paper>
      </Box>
      {type === "DISPO" || type === "MATERIAL" ? null : <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px -2px 4px #0000004a",
          maxWidth: "250px",
          borderLeft: "1px solid #888",
          overflow: "auto",
          position: "relative",
          background: "#fafafa",
        }}
      >
        <OpenRequests
          setCalendar={function (
            type: PossibleCalendarTypes,
            reference: number,
            date: dayjs.Dayjs
          ): void {
            setType(type);
            setReference(reference);
            calendar.current?.setCalendar(
              date.subtract(1, "day"),
              date.add(8, "days")
            );
          }}
        />
      </Box>}
    </Box>
  );
}
