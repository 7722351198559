import {
  Add,
  PostAdd,
  Error as ErrorIcon,
  Visibility,
  Delete,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useMemo, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { ProjectFile, StoffstromOrder } from "../Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import ApiService from "@/api/ApiService";

const baseStyle = {
  flex: 1,
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  backgroundColor: "#eaeaea",
};

const acceptStyle = {
  backgroundColor: "rgb(223, 255, 205)",
};

const rejectStyle = {
  backgroundColor: "rgb(255, 205, 205)",
};

export default function AddFileDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: ProjectFile) => void;
  OpenButton: any;
  initialState: ProjectFile;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [open, setOpen] = useState(false);

  const [createdAt, setCreatedAt] = useState(
    dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
  );
  const [description, setDescription] = useState("");
  const [filename, setFilename] = useState("");
  const [id, setId] = useState(0);
  

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const onDrop = async (acceptedFiles) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await ApiService.uploadFile()(acceptedFiles[0]);
      setFilename(response.file);
      setIsLoading(false);
      setIsError(false);
    } catch (e) {
      console.error(e);
      setIsError(true);
      setIsLoading(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: filename.length > 0 || isLoading,
    onDrop,
    accept: { "*": [] },
  });

  const openAndSet = useCallback(() => {
    setDescription(!initialState ? "" : initialState.description);
    setFilename(!initialState ? "" : initialState.filename);
    setId(!initialState ? 0 : initialState.id);
    setCreatedAt(
      !initialState
        ? dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
        : initialState.createdAt
    );

    setOpen(true);
  }, [initialState]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="md" onClose={close} fullWidth>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Datei/Bild {id > 0 ? "bearbeiten" : "hochladen"}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Beschreibung">
              <TextField
                size="small"
                fullWidth
                value={description}
                placeholder="Beschreibung"
                onChange={(e) =>
                  setDescription((e.target as HTMLInputElement).value)
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Bild/Dokument hinzufügen">
              <Paper variant="outlined" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {filename.length > 0 ? (
                  <Fragment>
                    Datei erfolgreich hochgeladen.
                    <Box sx={{ display: "flex", mt: "10px", gap: "10px" }}>
                      <Button href={"https://bob.juchem-gruppe.de/backend/api/image/"+filename} target="_blank" startIcon={<Visibility />} variant="outlined">
                        Ansehen
                      </Button>
                      <Button onClick={() => setFilename("")} color="error" startIcon={<Delete />}>
                        Löschen
                      </Button>
                    </Box>
                  </Fragment>
                ) : isError ? (
                  <Fragment>
                    <ErrorIcon color="error" /> Fehler beim Hochladen. Bitte
                    erneut versuchen.
                  </Fragment>
                ) : isLoading ? (
                  <CircularProgress color="info" />
                ) : (
                  <Box sx={{ textAlign: "center", color: "#444" }}>
                    <PostAdd />
                    <Box>Datei hier ablegen</Box>
                  </Box>
                )}
              </Paper>
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={filename.length === 0 || description.length === 0}
              onClick={() => {
                save({
                  id,
                  deleted: false,
                  description,
                  filename,
                  createdAt,
                });
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
