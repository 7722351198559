import ApiService from "@/shared/api/BaseApiService";
import { BackendEntity } from "@/shared/types";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useState } from "react";

export default function DeleteButton({
  type,
  id,
  text,
  invalidate,
  size,
  fontSize,
  xsx,
  sideEffect = () => {}
}: {
  type: string;
  id: number;
  text?: string;
  invalidate?: any[],
  size: "small" | "medium" | "large" | undefined;
  fontSize: "small" | "medium" | "large" | "inherit" | undefined;
  xsx: {},
  sideEffect?: () => void
}) {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    
    {
      mutationFn: ApiService.deleteEntity(type as BackendEntity, id),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: !!invalidate ? invalidate : [`get${type[0].toUpperCase()}${type.slice(1)}s`],
        });
        sideEffect();
      },
    }
  );

  const accept = () => {
    setOpen(false);
    mutation.mutate();
  };

  return <>{mutation.isLoading ? (
    <IconButton sx={!!xsx ? xsx : {}} size={!!size ? size : "medium"}>
      <CircularProgress size={20} />
    </IconButton>
  ) : mutation.isSuccess ? (
    <IconButton sx={!!xsx ? xsx : {}} size={!!size ? size : "medium"}>
      <CheckCircleTwoToneIcon fontSize={!!fontSize ? fontSize : "medium"} />
    </IconButton>
  ) : (
    <IconButton sx={!!xsx ? xsx : {}} size={!!size ? size : "medium"} aria-label="delete" onClick={() => setOpen(true)}>
      {mutation.isError ? <ErrorTwoToneIcon  fontSize={!!fontSize ? fontSize : "medium"} /> : <DeleteTwoToneIcon  fontSize={!!fontSize ? fontSize : "medium"} />}
    </IconButton>
  )}<Dialog
  open={open}
  onClose={() => setOpen(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Bitte bestätige den Löschvorgang"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {!!text ? text : "Möchtest du das wirklich entgültig löschen?"}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpen(false)}>Nein, nicht löschen!</Button>
    <Button variant="contained" onClick={accept} autoFocus>
      Ja, löschen!
    </Button>
  </DialogActions>
</Dialog></>;
}
