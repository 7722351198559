import ApiService from "@/shared/api/BaseApiService";
import { POI } from "@/shared/types";
import { Search, Tag } from "@mui/icons-material";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment, useEffect, useState } from "react";

export default function POIGeocoder({ setPosition, initialName = "" }) {
  const [searchField, setSearchField] = useState(initialName);
  const [results, setResults] = useState<POI[] | null>(null);

  const query = useQuery<POI[], AxiosResponse>({
    queryKey: ["getPOIs"],
    queryFn: ApiService.getEntities("poi"),
  });

  const select = () => {
    if (!Array.isArray(results) || results.length !== 1) {
      return;
    }
    setPosition({ lat: results[0].lat, lng: results[0].lng }, results[0].name, true);
  };

  useEffect(() => {
    const searchString = searchField.trim();
    const name = searchString.toLocaleLowerCase();

    if (!query.isSuccess || searchString.length < 2) {
      setResults(null);
      return;
    }

    const resultsO = [];
    for (let poi of query.data) {
      if (poi.name.trim().toLocaleLowerCase().includes(name)) {
        resultsO.push(poi);
      }
    }

    setResults(resultsO);
  }, [searchField]);

  return query.isSuccess ? (
    <Fragment>
      <OutlinedInput
        fullWidth
        size="small"
        sx={{ backgroundColor: "white" }}
        onKeyDown={(e) => (e.key === "Enter" ? select() : null)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => select()}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                event.preventDefault()
              }
              edge="end"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
        placeholder="POI suchen..."
        value={searchField}
        onChange={(e) => setSearchField((e.target as HTMLInputElement).value)}
      />
      {!Array.isArray(results) ? null : (
        <Paper
          variant="outlined"
          sx={{ mt: "10px", maxHeight: "300px", overflow: "auto" }}
        >
          {results.length === 0 ? (
            <Alert severity="info">
              <AlertTitle>Nichts gefunden.</AlertTitle>
              Du kannst den Marker auch manuell verschieben.
            </Alert>
          ) : (
            results.map((item, i) => (
              <Box
                key={i}
                onClick={() => setPosition({ lat: item.lat, lng: item.lng }, item.name, true)}
                sx={{
                  borderTop: "1px solid #ccc",
                  cursor: "pointer",
                  "&:first-child": { borderTop: "none" },
                  "&:hover": { background: "#f9f9ff" },
                }}
              >
                <Box sx={{ padding: "5px" }}>{item.name}</Box>
              </Box>
            ))
          )}
        </Paper>
      )}
    </Fragment>
  ) : (
    <Box
      sx={{
        minHeight: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
