import { create } from 'zustand'
import { devtools, persist } from "zustand/middleware";

interface GlobalState {
  pageTitle: string;
  setPageTitle: (title: string) => void;
  backdropVisible: boolean;
  setBackdropVisible: (backdropVisible: boolean) => void;
}

interface PersistantState {
  authenticated: boolean;
  setAuthenticated: (auth: boolean) => void;
  region: number,
  setRegion: (region: number) => void;
  type: any;
  setType: (type: any) => void;
  reference: any;
  setReference: (reference: any) => void;
  settings: any;
  setSettings: (reference: any) => void;
}

export const useGlobalStore = create<GlobalState>()(
  devtools(
    (set, get) => ({
      pageTitle: "",
      setPageTitle: (pageTitle) => set((state) => ({pageTitle})),
      backdropVisible: false,
      setBackdropVisible: (backdropVisible) => set((state) => ({backdropVisible}))
    }),
    {
      name: "global-storage",
    }
  )
);

export const usePersistantStore = create<PersistantState>()(
  devtools(
    persist(
      (set, get) => ({
        region: 0,
        type: null,
        reference: null,
        settings: {
          showCrewMembers: false,
          showCrewResources: false,
          showOtherStaff: false,
          showOtherResources: false,
          showMaterial: false,
        },
        setSettings: (settings) => set((state) => ({ settings })),
        setType: (type) => set((state) => ({ type })),
        setReference: (reference) => set((state) => ({ reference })),
        setRegion: (region) => set((state) => ({ region })),
        authenticated: false,
        setAuthenticated: (authenticated) => {
          if (!authenticated) {
            localStorage.setItem(import.meta.env.VITE_APP_NAME+"-token", "");
            localStorage.setItem(import.meta.env.VITE_APP_NAME+"-refreshToken", "");
            localStorage.setItem(import.meta.env.VITE_APP_NAME+"-rights", "");
          }
          return set((state) => ({ authenticated }));
        },
        
      }),
      {
        name: "persistant-storage",
      }
    )
  )
);
