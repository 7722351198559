import { Fragment } from "react/jsx-runtime";
import { CalDataLine, CalJob, CalProject } from "./InnerCalendar";
import {
  CalendarContext,
  CalendarElementsProps,
} from "@/components/shared/VerticalCalendar";
import { useContext } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { MaterialOrder } from "@/components/planning/Project";
import { createDeliveryText } from "@/components/planning/editors/MaterialTab";

const Wrapper = ({text, style = {}}) => text.length > 0 ? <div style={style} className="textWra">{text}</div> : null;

const Info = ({
  data,
  type,
}: {
  data: CalJob & { materialOrder: MaterialOrder };
  type: "DISPO" | "MATERIAL";
}) => (
  <div className="matInfo">
    <Wrapper text={data.materialOrder.amount+" x "+data.materialOrder.article?.name} />
    <Wrapper text={createDeliveryText(
      data.materialOrder.truckAmount,
      data.materialOrder.truckType,
      data.materialOrder.thermo,
      data.materialOrder.takt
    )} />
    <Wrapper style={{fontStyle: "italic"}} text={data.materialOrder.comment} />
  </div>
);

export default function MaterialLine({
  offset,
  type,
  data,
}: {
  offset: number;
  data: CalDataLine<CalProject, CalJob & { materialOrder: MaterialOrder }>;
}) {
  const navigate = useNavigate();
  const view = useContext(CalendarContext);
  const days = [];

  for (let date in data.elements) {
    const dd = dayjs(date, "YYYY-MM-DD");

    if (dd < view?.calStart || dd > view?.calEnd) continue;
    const exx = [];
    for (let i = 0; i < data.elements[date].length; i++) {
      const element = data.elements[date][i];

      exx.push(
        <div className={"materialOrder"} key={"materialOrder" + date + i}>
          <div className="topPart">
            <div
              className="vc-res-status"
              style={{
                background:
                  element.status === "PLANNING"
                    ? "#99D4FF"
                    : element.status === "RAW"
                    ? "#A6FF99"
                    : "#FF9999",
              }}
            >
             
            </div>
            {!!element.materialOrder.firstUnload ? (
              <em>{element.materialOrder.firstUnload} </em>
            ) : (
              ""
            )}
            {element.phase.project.name} ({element.phase.project.number})
          </div>
          <Info data={element} type={type} />
          <div className="resOrderEdit">
            <div className="resOrderButton">
              <Edit
                onClick={() => navigate("/project/" + element.phase.project.id, {state: {phaseId: element.phase.id, date}})}
                fontSize="inherit"
              />
            </div>
          </div>
        </div>
      );
    }

    days.push(
      <div
        className={"vc-date-res boxWrapper"}
        key={"phase" + data.id + "_" + date}
        style={{
          gridRow: offset,
          gridColumn: dd.diff(view.calStart, "days") + 2,
        }}
      >
        {exx}
      </div>
    );
  }

  return (
    <Fragment>
      {type === "DISPO" ? (
        <div
          key={"sidebar" + offset}
          className={"vc-sidebar project"}
          style={{
            gridRow: offset,
          }}
        >
          {!!data?.number ? data?.number + ": " : ""}
          {data.name}
        </div>
      ) : null}
      {days}
      <div
        className="vc-hl"
        key={"hl" + offset}
        style={{
          gridRow: offset,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
    </Fragment>
  );
}
