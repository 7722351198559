import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from "react";
import { Box, Paper } from "@mui/material";

export const MainContainer = (props: { paperless?: boolean, children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => {
  return (
    <Box sx={{ flex: 1, overflow: "auto", background: "#eee", padding: 2 }}>
      {!!props.paperless ? props.children : <Paper>{props.children}</Paper>}
    </Box>
  );
};
