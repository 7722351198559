import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export default function RegionSelector() {
  const query = useQuery<{id: number; name: string;}[], AxiosResponse>({
    queryKey: ["getRegions"],
    queryFn: ApiService.getEntities("region"),
  });

  const region = usePersistantStore((state) => state.region);
  const setRegion = usePersistantStore((state) => state.setRegion);

  return query.isSuccess ? (
    <FormControl sx={{ mx: 1 }} size="small">
      <Select
        value={region}
        label="Standort"
        sx={{
          color: "white",
          ".MuiSvgIcon-root ": {
            fill: "white !important",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        onChange={(event) => setRegion(event.target.value as number)}
      >
        <MenuItem value={0}>Alle Standorte</MenuItem>
        {query.data.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
}
