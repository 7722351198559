import BaseApiService from "@/shared/api/BaseApiService";
import { validate } from "@/shared/api/validate";
import ErrorDialog from "@/shared/dialogs/ErrorDialog";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import ListBasedManyToManySelect from "@/shared/forms/ListBasedManyToManySelect";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import { Right } from "@/shared/types";
import { WithId } from "@/shared/types_bitumen";
import { useGlobalStore, usePersistantStore } from "@/stores/global";
import {
  DeleteTwoTone,
  AddCircleOutlineTwoTone,
  ArrowRight,
  Save,
  Cancel,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import definitions, { displayFunctions, SingleDefinition } from "./definitions";
import LabelWrapper from "../root/LabelWrapper";

export default function SettingsEditor({}) {
  const { type, rawId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  const region = usePersistantStore(state => state.region);

  

  const definition = definitions[type] as SingleDefinition;

  if (!type || (definition.regionalized && region === 0)) return <Navigate replace to="/" />;


  const id = !rawId ? 0 : parseInt(rawId);

  const [object, setObject] = useState(
    Object.fromEntries(definition.fields.map((x) => [x.id, x.default]).concat(definition.regionalized ? [["region",region]] : []))
  );

  const setField = (changes: any) => {
    const newState = { ...object, ...changes };
    setObject(newState);
  };

  const [shouldFetch, setShouldFetch] = useState(id > 0);

  useEffect(
    () =>
      setPageTitle(
        definition.title + ": " + (id > 0 ? "Bearbeiten" : "Erstellen")
      ),
    [definition.title, id]
  );

  const query = useQuery<any, AxiosResponse>({
    queryKey: ["get" + type[0].toUpperCase() + type.slice(1), ""+id],
    queryFn: BaseApiService.getEntity(type, id, true, "settings"),
    enabled: shouldFetch,
  });

  useEffect(() => {
    if (!query.isSuccess || !query.data) return;
    setShouldFetch(false);
    setObject(query.data);
  }, [query.isSuccess]);

  const mutation = useMutation<any, AxiosResponse, any>({
    mutationFn:
      id > 0
        ? BaseApiService.editEntity(type, id, "settings")
        : BaseApiService.createEntity(type, true, { v: "settings" }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get" + type[0].toUpperCase() + type.slice(1) + "s"],
      });
      if (id > 0)
        queryClient.invalidateQueries({
          queryKey: ["get" + type[0].toUpperCase() + type.slice(1), ""+id],
        });
      navigate(-1);
    },
  });

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      <TopBar>
        <LoadingButton
          onClick={() => mutation.mutate(object)}
          loading={mutation.status === "pending"}
          variant="text"
          disabled={(!query.isError && shouldFetch) || query.isError}
          startIcon={<Save />}
        >
          Speichern
        </LoadingButton>
        <Button
          onClick={() => navigate(-1 /*"/settings/users"*/)}
          variant="text"
          startIcon={<Cancel />}
        >
          Abbrechen
        </Button>
      </TopBar>
      <MainContainer>
        {!query.isError && shouldFetch ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : query.isError ? (
          <ErrorIndicator />
        ) : (
          <Box>
            {definition.fields.map((field) => (
              <LabelWrapper key={field.id} label={field.title}>
                {field.type === "string" ? (
                  <TextField
                    size="small"
                    fullWidth
                    disabled={id > 0 && !!field.disableOnEdit}
                    value={object[field.id]}
                    placeholder={field.title}
                    onChange={(e) =>
                      setField({
                        [field.id]: (e.target as HTMLInputElement).value,
                      })
                    }
                  />
                ) : field.type === "enum" ? (
                  <ToggleButtonGroup
                    exclusive
                    disabled={id > 0 && !!field.disableOnEdit}
                    value={object[field.id]}
                    onChange={(_, status) =>
                      status &&
                      setField({
                        [field.id]: status,
                      })
                    }
                  >
                    {Object.entries(field.values).map(([key, value]) => <ToggleButton value={key}>
                      {value}
                    </ToggleButton>)}
                  </ToggleButtonGroup>
                ) : field.type === "boolean" ? (
                  <ToggleButtonGroup
                    exclusive
                    disabled={id > 0 && !!field.disableOnEdit}
                    value={object[field.id]}
                    onChange={(_, status) =>
                      status &&
                      setField({
                        [field.id]: status,
                      })
                    }
                  >
                    <ToggleButton color="success" value={true}>
                      Ja
                    </ToggleButton>
                    <ToggleButton color="error" value={false}>
                      Nein
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : field.type === "ManyToOne" ? (
                    <ManyToOneSelect<{id: number, name: string}>
                    disabled={id > 0 && !!field.disableOnEdit}
                    size="small"
                    entity={field.relation}
                    showWithoutChoice
                    remoteFilter={field.regionalized ? { region: ["region", region] } : undefined}
                    autoSelect={false}
                    displayFunction={displayFunctions[field.relation] ?? undefined}
                    currentId={object[field.id] ?? 0}
                    setId={id => setField({
                        [field.id]: id > 0 ? id : null,
                    })}
                    placeholder={field.title}
                  />
                ) : field.type === "ManyToMany" ? (
                    <ListBasedManyToManySelect<{id: number, name: string}>
                    disabled={id > 0 && !!field.disableOnEdit}
                    groupByFunction={field.groupBy}
                    entity={typeof field.relation === "string" ? field.relation : field.relation(object)}
                    remoteFilter={field.regionalized ? { region: ["region", region], ...(field.remoteFilter ?? {}) } : (field.remoteFilter ?? undefined)}
                    displayFunction={displayFunctions[typeof field.relation === "string" ? field.relation : field.relation(object)] ?? undefined}
                    currentIds={object[field.id] ?? []}
                    setIds={ids => setField({
                        [field.id]: ids,
                    })}
                  />
                ) : null}
              </LabelWrapper>
            ))}
          </Box>
        )}
      </MainContainer>
      <ErrorDialog forceOpen={query.isError && query.error.status === 401} />
    </Box>
  );
}
