import { TravelExplore, Place } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Fragment, useState } from "react";
import AddressGeocoder from "./AddressGeocoder";
import POIGeocoder from "./POIGeocoder";

export default function Geocoder({ setPosition, noPOIs = false, initialName="", initialType="ADDRESS" }) {
  const [variant, setVariant] = useState<"ADDRESS" | "POI">(initialType);

  return (
    <Fragment>
      {!noPOIs ? <ToggleButtonGroup
        color="primary"
        value={variant}
        size="small"
        fullWidth
        sx={{mb: "8px"}}
        exclusive
        onChange={(_, v) => v !== null && setVariant(v)}
      >
        <ToggleButton value="ADDRESS">
          <TravelExplore sx={{ mr: 1 }} />
          Adresse
        </ToggleButton>
        <ToggleButton value="POI">
          <Place sx={{ mr: 1 }} />
          POI
        </ToggleButton>
      </ToggleButtonGroup> : null}
      {variant === "ADDRESS" || noPOIs ? (
        <AddressGeocoder initialName={initialType === "ADDRESS" ? initialName : ""} setPosition={setPosition} />
      ) : (
        <POIGeocoder initialName={initialType === "POI" ? initialName : ""} setPosition={setPosition} />
      )}
    </Fragment>
  );
}
