import {
  Children,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from "react";
import { Box, Divider, SxProps, Theme } from "@mui/material";

export const TopBar = (props: {
  children:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
  align?: "center" | "left";
  sx?: SxProps<Theme> | undefined
}) => {
  const children = Children.toArray(props.children)
    .flatMap((x, i) => [
      <Divider key={"divider"+i} sx={{ mx: 1 }} orientation="vertical" flexItem />,
      x
    ])
    .slice(1);

    const sx = props.sx ? props.sx : {};

  return (
    <Box
      sx={{
        py: 0.8,
        px: 2,
        background: "white",
        borderBottom: 1,
        borderColor: "rgba(0, 0, 0, 0.42)",
        display: "flex",
        justifyContent: props.align === "center" ? "center" : "flex-start",
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
