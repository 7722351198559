import {
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PossibleCalendarTypes } from "../MainCalendar";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import { usePersistantStore } from "@/stores/global";

export default function SelectCalendarDialog({
  type,
  settings,
  setSettings,
}: {
  type: PossibleCalendarTypes;
  settings: { [key: string]: any };
  setSettings: (settings: { [key: string]: any }) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const close = useCallback(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  return (
    <Fragment>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        Ansicht
      </Button>
      <Menu open={open} anchorEl={anchorEl} onClose={close}>
        {[
          { key: "showMaterial", name: "Material" },
          { key: "showCrewMembers", name: "Kolonnenmitglieder" },
          { key: "showCrewResources", name: "Kolonnengeräte" },
          { key: "showOtherStaff", name: "Zusätzliche Mitarbeiter" },
          { key: "showOtherResources", name: "Zusätzliche Geräte" },
        ].map((x) => (
          <MenuItem
            onClick={() => {
              setSettings({ ...settings, [x.key]: !settings[x.key] });
              close();
            }}
            key={x.key}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {!!settings[x.key] ? <CheckBox /> : <CheckBoxOutlineBlank />}
              <div>{x.name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
