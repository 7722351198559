import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { validate } from "@/shared/api/validate";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import BaseApiService from "../api/BaseApiService";
import ErrorDialog from "../dialogs/ErrorDialog";

export default function SetPassword({setPageTitle = _ => {}}) {
  const [old, setOld] = useState("");
  const [new2, setNew2] = useState("");
  const [new1, setNew1] = useState("");
  const [validationMessages, setValidationMessages] = useState<String[]>([]);


  useEffect(() => setPageTitle("Passwort ändern"), []);

  const mutation = useMutation<
    any,
    AxiosResponse,
    { old: string; new: string }
  >({mutationFn: BaseApiService.changePassword()});

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      <TopBar>
        <LoadingButton
          onClick={() =>
            validate(
              { new: new1 === new2 ? new1 : "", old },
              mutation.mutate,
              setValidationMessages
            )
          }
          loading={mutation.status === "loading" || mutation.status === "pending"}
          variant="text"
          startIcon={<SaveTwoToneIcon />}
        >
          Speichern
        </LoadingButton>
      </TopBar>
      <MainContainer>
        {mutation.isLoading ? (
          <Box
            sx={{
              display: "flex",
              padding: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ padding: 2 }}>
            {validationMessages.length ? (
              <Alert severity="error">
                <AlertTitle>Fehlende Daten</AlertTitle>
                {validationMessages.map((message, key) => (
                  <div key={key}>{message}</div>
                ))}
              </Alert>
            ) : null}
            {mutation.isSuccess ? (
              <Alert severity="success">Passwort erfolgreich geändert.</Alert>
            ) : mutation.isError ? (
              <Alert severity="error">Fehler beim ändern des Passworts.</Alert>
            ) : null}
            <TextField
              label="Altes Passwort"
              fullWidth
              type="password"
              margin="dense"
              value={old}
              onChange={(e) => setOld((e.target as HTMLInputElement).value)}
            />
            <TextField
              label="Neues Passwort"
              fullWidth
              margin="dense"
              type="password"
              value={new1}
              onChange={(e) => setNew1((e.target as HTMLInputElement).value)}
            />
            <TextField
              label="Wiederholung"
              fullWidth
              margin="dense"
              value={new2}
              type="password"
              onChange={(e) => setNew2((e.target as HTMLInputElement).value)}
            />
          </Box>
        )}
      </MainContainer>
      <ErrorDialog
        forceOpen={mutation.isError && mutation.error.status === 401}
      />
    </Box>
  );
}
