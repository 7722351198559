import ApiService from "@/shared/api/BaseApiService";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import { POI } from "@/shared/types";
import { ArrowForward, Search } from "@mui/icons-material";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment, useState } from "react";

const getName = (item) => (item.resultType === "locality" &&
!!item.address &&
!!item.address.postalCode &&
item.address.postalCode.length > 0
  ? item.address.postalCode + " "
  : "") + item.title;

export default function AddressGeocoder({ setPosition, initialName }) {
  const [searchField, setSearchField] = useState(initialName);



  const search = useMutation<any, AxiosResponse, { search: string }>(
    
    {
      mutationFn: ApiService.geocode(),
      onSuccess: (answer) => {
        if (answer.items.length === 1) {
          setPosition(answer.items[0].position, getName(answer.items[0]), false);
          setSearchField("");
          search.reset();
        }
      },
    }
  );

  const geocode = (searchStringUntrimmed: string) => {
    const searchString = searchStringUntrimmed.trim();
    search.mutate({
      search: searchString,
    });
  };


  return (
    <Fragment>
      <OutlinedInput
        fullWidth
        size="small"
        sx={{ backgroundColor: "white" }}
        onKeyDown={(e) => (e.key === "Enter" ? geocode(searchField) : null)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              disabled={search.status === "pending" || search.status === "loading"}
              onClick={() => geocode(searchField)}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                event.preventDefault()
              }
              edge="end"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Adresse suchen..."
        value={searchField}
        onChange={(e) => setSearchField((e.target as HTMLInputElement).value)}
      />
      {search.isIdle ? null : (
        <Paper
          variant="outlined"
          sx={{ mt: "10px", maxHeight: "300px", overflow: "auto" }}
        >
          {search.isError ? (
            <ErrorIndicator />
          ) : search.status === "pending" || search.status === "loading"  ? (
            <Box
              sx={{
                minHeight: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : search.data.items.length === 0 ? (
            <Alert severity="info">
              <AlertTitle>Nichts gefunden.</AlertTitle>
              Du kannst den Marker auch manuell verschieben.
            </Alert>
          ) : (
            search.data.items.map((item, i) => (
              <Box
                key={i}
                onClick={() => setPosition(item.position, getName(item), false)}
                sx={{
                  borderTop: "1px solid #ccc",
                  cursor: "pointer",
                  "&:first-child": { borderTop: "none" },
                  "&:hover": { background: "#f9f9ff" },
                }}
              >
                <Box sx={{ padding: "5px" }}>{getName(item)}</Box>
              </Box>
            ))
          )}
        </Paper>
      )}
    </Fragment>
  );
}
